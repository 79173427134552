<template>
  <v-dialog
    class="sign-in-register-dialog"
    :value="value"
    persistent
    max-width="480px"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-toolbar color="primary" dark dense>
        <v-btn
          icon
          :disabled="window === 'signIn'"
          @click="
            window = 'signIn';
            signInData.password = '';
          "
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-icon left>mdi-{{ window === 'signIn' ? 'login-variant' : 'account-plus' }}</v-icon>
        <v-toolbar-title>{{ window === 'signIn' ? '登入' : '註冊' }}</v-toolbar-title>
        <v-spacer />
        <!-- TODO: make languages menu in a component -->
        <v-menu offset-y transition="slide-y-transition">
          <template #activator="{ attrs, on }">
            <v-btn text v-bind="attrs" v-on="on">
              <v-icon left>mdi-translate</v-icon>
              <span class="subtitle-1 text-capitalize">{{ currentLanguageName }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(language, i) in languages"
              :key="`languages-${i}`"
              @click="(currentLanguageName = language.name), language.apply()"
            >
              <v-list-item-title>{{ language.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
          <v-icon>mdi-{{ $vuetify.theme.dark ? 'weather-night' : 'white-balance-sunny' }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-window v-model="window" touchless>
        <v-window-item
          v-for="windowItem in ['signIn', 'register']"
          :key="`window-${windowItem}`"
          :value="windowItem"
        >
          <v-container>
            <v-form
              :id="`${windowItem}Form`"
              v-model="areFormsValid[windowItem]"
              :disabled="requesting"
              @submit.prevent
            >
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      id="email"
                      v-model="signInData.email"
                      clearable
                      dense
                      label="電子信箱"
                      name="email"
                      prepend-icon="mdi-email"
                      type="email"
                      :rules="[inputRules.required, inputRules.email]"
                      @input="localStorage.setItem('signIn.email', $event)"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="signInData.password"
                      clearable
                      dense
                      counter
                      label="密碼"
                      prepend-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      :rules="[inputRules.required, inputRules.password]"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <template v-if="windowItem === 'register'">
                <v-divider />
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        id="name"
                        v-model="registerData.name"
                        clearable
                        dense
                        label="姓名"
                        name="name"
                        prepend-icon="mdi-account"
                        :rules="[inputRules.required]"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        id="photoUrl"
                        v-model="registerData.photoUrl"
                        clearable
                        dense
                        type="url"
                        label="頭像網址（選填）"
                        name="photoUrl"
                        prepend-icon="mdi-account-circle"
                        :rules="[inputRules.url]"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-form>
          </v-container>
        </v-window-item>
      </v-window>
      <v-divider />
      <v-card-actions>
        <v-btn
          v-if="window === 'signIn'"
          color="warning"
          depressed
          :disabled="requesting"
          @click="
            signInData.password = '';
            window = 'register';
          "
        >
          註冊
        </v-btn>
        <v-spacer />
        <v-checkbox
          v-model="rememberMe"
          class="px-2 py-0 ma-0"
          hide-details
          dense
          :disabled="requesting"
          @click="localStorage.setItem('signIn.rememberMe', rememberMe)"
        >
          <template #label>
            <span style="line-height: normal" class="ml-n1 text-button">記住我</span>
          </template>
        </v-checkbox>
        <v-btn
          depressed
          type="submit"
          :form="`${window}Form`"
          color="primary"
          :loading="requesting"
          :disabled="requesting || !areFormsValid[window]"
          @click="window === 'signIn' ? signIn() : register()"
        >
          {{ window === 'signIn' ? '登入' : '註冊並登入' }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_isShowing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import blakejs from 'blakejs';
import firebase from 'firebase/app';

import inputRules from '@/mixins/inputRules';
import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
import { auth, firestore } from '@/firebase';

export default Vue.component(
  'sign-in-register-dialog',
  Vue.extend({
    name: 'SignInRegisterDialog',
    mixins: [inputRules, messageSnackbar],
    props: {
      value: { type: Boolean, default: true },
    },
    data: () => ({
      // TODO: make localStorage become Vue plugin https://bit.ly/2DsS4ES
      localStorage: window.localStorage,
      // TODO: make languages config api in i18n.js
      currentLanguageName: '繁體中文',
      languages: [
        {
          name: 'English',
          apply() {
            document.querySelector('html').setAttribute('lang', 'en');
          },
        },
        {
          name: '繁體中文',
          apply() {
            document.querySelector('html').setAttribute('lang', 'zh-Hant');
          },
        },
        {
          name: '简体中文',
          apply() {
            document.querySelector('html').setAttribute('lang', 'zh-Hans');
          },
        },
      ],

      window: 'signIn',
      signInData: {
        email: '',
        password: '',
      },
      registerData: {
        name: '',
        photoUrl: '',
      },
      areFormsValid: {
        signIn: false,
        register: false,
      },
      rememberMe: false,
      showPassword: false,
      requesting: false,
    }),
    computed: {
      authPersistence() {
        return this.rememberMe
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION;
      },
    },
    created() {
      this.rememberMe = JSON.parse(localStorage.getItem('signIn.rememberMe')) === true;
      this.signInData.email = localStorage.getItem('signIn.email') ?? '';
    },
    methods: {
      preprocessData() {
        this.signInData.email = this.signInData.email.trim();
        this.signInData.password = blakejs.blake2bHex(this.signInData.password);
        this.registerData.name = this.registerData.name.trim();
        this.registerData.photoUrl = this.registerData.photoUrl.trim();
      },
      async signIn() {
        this.$_mixin_messageSnackbar_hide();
        this.requesting = true;
        const showPassword = this.showPassword;
        this.showPassword = false;
        this.preprocessData();
        const userCredential = await auth
          .setPersistence(this.authPersistence)
          .then(() =>
            auth.signInWithEmailAndPassword(this.signInData.email, this.signInData.password),
          )
          .catch((error) => {
            if (error.code === 'auth/user-not-found') this.window = 'register';
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定');
          });
        this.signInData.password = '';
        if (userCredential) {
          if (auth.currentUser) {
            this.$store.commit('sign', auth.currentUser);
            this.$emit('input', false);
          } else {
            this.$_mixin_messageSnackbar_show(
              MessageSnackbarType.error,
              '發生了未知的錯誤。',
              '確定',
            );
          }
        }
        this.showPassword = showPassword;
        this.requesting = false;
      },
      async register() {
        this.$_mixin_messageSnackbar_hide();
        this.requesting = true;
        const showPassword = this.showPassword;
        this.showPassword = false;
        this.preprocessData();
        const userCredential = await auth
          .setPersistence(this.authPersistence)
          .then(() =>
            auth.createUserWithEmailAndPassword(this.signInData.email, this.signInData.password),
          )
          .catch((error) => {
            if (error.code === 'auth/email-already-in-use') this.signInData.email = '';
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定');
          });
        this.signInData.password = '';
        if (userCredential) {
          if (auth.currentUser) {
            await auth.currentUser
              .updateProfile({
                displayName: this.registerData.name,
                photoURL: this.registerData.photoUrl.length > 0 ? this.registerData.photoUrl : null,
              })
              .then(() =>
                firestore.collection('users').doc(auth.currentUser.uid).set({
                  role: 'doctor', // TODO: authorization management
                  ownedPatients: Object(),
                }),
              )
              .catch((error) =>
                this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定'),
              );
            this.$store.commit('sign', auth.currentUser);
            this.$emit('input', false);
          } else {
            this.$_mixin_messageSnackbar_show(
              MessageSnackbarType.error,
              '發生了未知的錯誤。',
              '確定',
            );
          }
        }
        this.showPassword = showPassword;
        this.requesting = false;
      },
    },
  }),
);
</script>

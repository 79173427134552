var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","expand-on-hover":!_vm.$vuetify.breakpoint.mobile,"permanent":!_vm.$vuetify.breakpoint.mobile,"touchless":!_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.navDrawerIsOpen),callback:function ($$v) {_vm.navDrawerIsOpen=$$v},expression:"navDrawerIsOpen"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.navListItems),function(item,i){return _c('v-list-item',{key:("navListItems-" + i),attrs:{"to":item.path}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1),_c('v-app-bar',{staticStyle:{"width":"100vw"},attrs:{"app":"","clipped-left":"","color":"primary","dark":"","dense":""}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.navDrawerIsOpen = !_vm.navDrawerIsOpen}}}):_c('v-icon',{staticClass:"ml-n3",staticStyle:{"width":"48px","height":"48px"}},[_vm._v(" mdi-"+_vm._s(_vm.$store.state.app.icon)+" ")]),_c('v-app-bar-title',{staticClass:"pl-0"},[_vm._v(_vm._s(_vm.$store.state.app.name))]),_c('v-spacer'),(!_vm.requesting && !_vm.isUnderMaintenance)?[(_vm._.isPlainObject(_vm.$store.state.profile))?_c('v-menu',{attrs:{"max-width":"240px","offset-y":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 pa-1",style:({ borderRadius: ((_vm.$vuetify.breakpoint.mobile ? 24 : 16) + "px") }),attrs:{"text":"","min-width":"0","height":"48"}},'v-btn',attrs,false),on),[_c('v-avatar',{staticClass:"ma-1",attrs:{"size":"32"}},[_c('v-img',{staticStyle:{"background-color":"#e5e5e5"},attrs:{"src":_vm.photoUrl,"lazy-src":_vm.lazyPhotoUrl}})],1),(!_vm.$vuetify.breakpoint.mobile)?_c('span',{staticClass:"ma-1 subtitle-1",staticStyle:{"text-transform":"none"}},[_vm._v(" "+_vm._s(_vm.$store.state.profile.name)+" ")]):_vm._e()],1)]}}],null,false,1316923032)},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{staticStyle:{"background-color":"#e5e5e5"},attrs:{"src":_vm.photoUrl,"lazy-src":_vm.lazyPhotoUrl}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$store.state.profile.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$store.state.profile.email))])],1),_c('v-list-item-action')],1)],1),_c('v-card-actions',[_c('measurement-manage-plan-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"teal","outlined":"","text":"","block":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clipboard-text-multiple")]),_vm._v(" 量測計畫管理 ")],1)]}}],null,false,1326087644),model:{value:(_vm.measurementManagePlanDialogIsOpen),callback:function ($$v) {_vm.measurementManagePlanDialogIsOpen=$$v},expression:"measurementManagePlanDialogIsOpen"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"pink","outlined":"","text":"","block":""},on:{"click":function($event){return _vm.signOut()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-logout-variant")]),_vm._v(" 登出 ")],1)],1)],1)],1):_vm._e(),(_vm._.isPlainObject(_vm.$store.state.profile) && _vm.$store.state.user)?_c('v-menu',{attrs:{"max-width":"240px","offset-y":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 pa-1",staticStyle:{"border-radius":"16px"},attrs:{"text":"","min-width":"0","height":"48"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-account")]),_c('span',{staticClass:"ma-1 subtitle-1",staticStyle:{"text-transform":"none"}},[_vm._v(" "+_vm._s(_vm.$store.state.patient ? _vm.$store.state.patient.profile.fullName : _vm.requesting ? '載入中…' : '未選擇病人')+" ")])],1)]}}],null,false,3715051518),model:{value:(_vm.patientsMenuIsOpen),callback:function ($$v) {_vm.patientsMenuIsOpen=$$v},expression:"patientsMenuIsOpen"}},[_c('v-card',[(_vm.$store.state.patient)?[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$store.state.patient.profile.fullName))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$store.state.patient.profile.idNumber)+" ")])],1),_c('v-list-item-action',[_c('add-or-edit-patient-dialog',{key:_vm.editPatientDialogKey,attrs:{"patient-id":_vm.$store.state.patientId},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-edit")])],1)]}}],null,false,3283081621),model:{value:(_vm.editPatientDialogIsOpen),callback:function ($$v) {_vm.editPatientDialogIsOpen=$$v},expression:"editPatientDialogIsOpen"}})],1)],1),_c('v-card-actions',{staticClass:"flex-column py-1"},[_c('edit-blood-pressure-goals-dialog',{key:'bpg' + _vm.editBloodPressureGoalsDialogKey,scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"deep-orange","outlined":"","text":"","block":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-water-check")]),_vm._v(" 編輯血壓目標 ")],1)]}}],null,false,574713090),model:{value:(_vm.editBloodPressureGoalsDialogIsOpen),callback:function ($$v) {_vm.editBloodPressureGoalsDialogIsOpen=$$v},expression:"editBloodPressureGoalsDialogIsOpen"}}),_c('measurement-select-plan-dialog',{key:'msp' + _vm.measurementSelectPlanDialogKey,scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"green","outlined":"","text":"","block":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clipboard-text-play")]),_vm._v(" 選擇／清除量測計畫 ")],1)]}}],null,false,499938697),model:{value:(_vm.measurementSelectPlanDialogIsOpen),callback:function ($$v) {_vm.measurementSelectPlanDialogIsOpen=$$v},expression:"measurementSelectPlanDialogIsOpen"}})],1),_c('v-divider'),_c('v-card-subtitle',{staticClass:"pa-2 mb-n2"},[_vm._v("執行中的量測計畫：")]),_c('v-list-item',{attrs:{"two-line":_vm.hasMeasurementPlanInExecution}},[(_vm.hasMeasurementPlanInExecution)?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$store.state.patient.measurementPlanInExecution.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" 開始時間："+_vm._s(_vm.Date$.withoutSeconds( _vm.$store.state.patient.measurementPlanInExecution.beginAt.toDate() ).shortLocalizedString)+" ")])],1):_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text--disabled"},[_vm._v("（未選擇計畫）")])],1)],1),_c('v-card-actions',[_c('measurement-manage-schedule-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"teal","outlined":"","text":"","block":"","disabled":!_vm.hasMeasurementPlanInExecution}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clipboard-clock")]),_vm._v(" 量測排程管理 ")],1)]}}],null,false,136261055),model:{value:(_vm.measurementManageScheduleDialogIsOpen),callback:function ($$v) {_vm.measurementManageScheduleDialogIsOpen=$$v},expression:"measurementManageScheduleDialogIsOpen"}})],1),_c('v-divider')]:_vm._e(),_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"240px"},attrs:{"dense":"","subheader":""}},[_c('v-subheader',[_vm._v("病人列表")]),(Object.keys(_vm.$store.state.user.ownedPatients).length > 0)?_vm._l((_vm.$store.state.user.ownedPatients),function(patient,patientId){return _c('v-list-item',{key:("userOwnedPatients-" + patientId),on:{"click":function($event){return _vm.changePatient(patientId)}}},[(patient !== null && _vm.$store.state.patientId !== patientId)?[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-radiobox-blank")])],1),(patient.profile)?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(patient.profile.fullName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(patient.profile.idNumber))])],1):_vm._e()]:(patient !== null)?[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-radiobox-marked")])],1),(patient.profile)?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(patient.profile.fullName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(patient.profile.idNumber))])],1):_vm._e()]:_vm._e()],2)}):_c('v-list-item',[_c('v-list-item-content',{staticClass:"text--disabled"},[_c('v-list-item-title',[_vm._v("（無病人）")])],1)],1)],2),_c('v-card-actions',[_c('add-or-edit-patient-dialog',{key:_vm.addPatientDialogKey,scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":"","text":"","block":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" 新增病人 ")],1)]}}],null,false,3857075373),model:{value:(_vm.addPatientDialogIsOpen),callback:function ($$v) {_vm.addPatientDialogIsOpen=$$v},expression:"addPatientDialogIsOpen"}})],1)],2)],1):_vm._e()]:_vm._e(),_c('v-menu',{attrs:{"left":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.appMenuIsOpen),callback:function ($$v) {_vm.appMenuIsOpen=$$v},expression:"appMenuIsOpen"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){_vm.$vuetify.theme.dark = !_vm.$vuetify.theme.dark}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-"+_vm._s(_vm.$vuetify.theme.dark ? 'weather-night' : 'white-balance-sunny')+" ")])],1),_c('v-list-item-title',[_vm._v(" 主題："+_vm._s(("" + (_vm.$vuetify.theme.dark ? '暗' : '亮')))+" ")])],1),_c('about-dialog',{on:{"input":function($event){$event ? null : (_vm.appMenuIsOpen = false)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-information")])],1),_c('v-list-item-title',[_vm._v("關於")])],1)]}}]),model:{value:(_vm.aboutDialogIsOpen),callback:function ($$v) {_vm.aboutDialogIsOpen=$$v},expression:"aboutDialogIsOpen"}})],1)],1)],2),_c('v-main',[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(!_vm.requesting && !_vm.isUnderMaintenance)?_c('router-view',{staticStyle:{"height":"calc(100vh - 48px)"}}):_vm._e()],1)],1),_c('sign-in-register-dialog',{model:{value:(_vm.signInRegisterDialogIsOpen),callback:function ($$v) {_vm.signInRegisterDialogIsOpen=$$v},expression:"signInRegisterDialogIsOpen"}}),_c('v-fade-transition',[(_vm.requesting || _vm.isUnderMaintenance)?_c('v-overlay',{attrs:{"z-index":"2147483647"}},[(_vm.requesting)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}}):_c('span',{staticClass:"text-h3"},[_vm._v("維護中，系統已經暫時下線…")])],1):_vm._e()],1),_c('message-snackbar',{attrs:{"type":_vm.$data.$_mixin_messageSnackbar_type,"message":_vm.$data.$_mixin_messageSnackbar_message,"action":_vm.$data.$_mixin_messageSnackbar_action,"timeout":_vm.$data.$_mixin_messageSnackbar_timeout},on:{"result":function($event){_vm.result = $event}},model:{value:(_vm.$data.$_mixin_messageSnackbar_isShowing),callback:function ($$v) {_vm.$set(_vm.$data, "$_mixin_messageSnackbar_isShowing", $$v)},expression:"$data.$_mixin_messageSnackbar_isShowing"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog
    class="measurement-manage-schedule-dialog"
    :value="value"
    :persistent="requesting"
    fullscreen
    transition="dialog-bottom-transition"
    @input="$emit('input', $event)"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <v-card v-if="hasMeasurementPlanInExecution">
      <v-toolbar color="primary" dark dense style="z-index: 1">
        <v-icon left>mdi-clipboard-clock</v-icon>
        <v-toolbar-title>量測排程管理</v-toolbar-title>
        <v-spacer />
        <v-btn icon :disabled="requesting" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        height="calc(100vh - 155px)"
        fixed-header
        :footer-props="{ showCurrentPage: true, showFirstLastPage: true }"
        :items="measurementSchedules"
        :items-per-page="15"
        :headers="measurementSchedulesDataTableHeaders"
      >
        <template #top>
          <v-toolbar flat dense>
            <v-toolbar-title>
              {{ $store.state.patient.measurementPlanInExecution.name }}（開始時間：{{
                Date$.withoutSeconds(
                  $store.state.patient.measurementPlanInExecution.beginAt.toDate(),
                ).shortLocalizedString
              }}）
            </v-toolbar-title>
            <v-spacer />
            <div class="ma-n3">
              <measurement-add-or-edit-schedule-dialog
                :key="addMeasurementScheduleDialogKey"
                v-model="addMeasurementScheduleDialogIsOpen"
              >
                <template #activator="{ on: onDialog, attrs: attrsDialog }">
                  <v-tooltip bottom>
                    <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
                      <v-btn
                        :rounded="!$vuetify.breakpoint.mobile"
                        :text="!$vuetify.breakpoint.mobile"
                        :outlined="!$vuetify.breakpoint.mobile"
                        :icon="$vuetify.breakpoint.mobile"
                        color="primary"
                        v-bind="{ ...attrsDialog, ...attrsTooltip }"
                        v-on="{ ...onDialog, ...onTooltip }"
                      >
                        <v-icon :left="!$vuetify.breakpoint.mobile">mdi-plus</v-icon>
                        <template v-if="!$vuetify.breakpoint.mobile">新增額外的排程</template>
                      </v-btn>
                    </template>
                    <span>新增額外的排程</span>
                  </v-tooltip>
                </template>
              </measurement-add-or-edit-schedule-dialog>
            </div>
          </v-toolbar>
        </template>
        <template #[`item.beginAt`]="{ item }">
          {{ item.beginAtString }}
        </template>
        <template #[`item.actions`]="{ item }">
          <div style="min-width: 72px" class="ma-n2">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  :disabled="
                    Object.entries(measurementScheduleStatus).filter(
                      (v) => v[1] === item.status,
                    )[0][0] !== 'pending'
                  "
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="
                    editingMeasurementScheduleId = item.id;
                    editMeasurementScheduleDialogIsOpen = true;
                  "
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>編輯量測排程</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="beforeDeleteMeasurementSchedule(item.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>刪除量測排程</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <v-fade-transition>
        <v-overlay v-model="requesting" absolute>
          <v-progress-circular indeterminate size="32" />
        </v-overlay>
      </v-fade-transition>
    </v-card>
    <measurement-add-or-edit-schedule-dialog
      v-if="_.isString(editingMeasurementScheduleId)"
      :id="editingMeasurementScheduleId"
      :key="editMeasurementScheduleDialogKey"
      v-model="editMeasurementScheduleDialogIsOpen"
      @input="$event ? null : (editingMeasurementScheduleId = null)"
    />
    <message-dialog
      v-model="$data.$_mixin_messageDialog_isOpen"
      :type="$data.$_mixin_messageDialog_type"
      :title="$data.$_mixin_messageDialog_title"
      :message="$data.$_mixin_messageDialog_message"
      :yes-button="$data.$_mixin_messageDialog_yesButton"
      :no-button="$data.$_mixin_messageDialog_noButton"
      :cancel-button="$data.$_mixin_messageDialog_cancelButton"
      :persistent="$data.$_mixin_messageDialog_persistent"
      @yes="messageDialogYesAction()"
    />
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_isShowing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </v-dialog>
</template>

<script>
import Vue from 'vue';

import MeasurementAddOrEditScheduleDialog from '@/components/App/Measurement/MeasurementAddOrEditScheduleDialog';
import extensions from '@/mixins/extensions';
import messageDialog, { MessageDialogType, MessageDialogButtons } from '@/mixins/messageDialog';
import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
import { Date } from '@/extensions';
import { firestore } from '@/firebase';

export default Vue.component(
  'measurement-manage-schedule-dialog',
  Vue.extend({
    name: 'MeasurementManageScheduleDialog',
    components: { MeasurementAddOrEditScheduleDialog },
    mixins: [extensions, messageDialog, messageSnackbar],
    props: {
      value: { type: Boolean, default: true },
    },
    data: () => ({
      requesting: false,
      messageDialogYesAction: null,
      addMeasurementScheduleDialogKey: 0,
      addMeasurementScheduleDialogIsOpen: false,
      editMeasurementScheduleDialogKey: 0,
      editMeasurementScheduleDialogIsOpen: false,
      editingMeasurementScheduleId: null,
      deletingMeasurementScheduleId: null,
      measurementSchedulesDataTableHeaders: [
        { text: '#', value: 'number', align: 'end', sortable: false },
        { text: '開始於', value: 'beginAt', align: 'center' },
        { text: '量測次數', value: 'eachMeasurementTimes', align: 'end' },
        { text: '量測間隔（分鐘）', value: 'eachMeasurementIntervalInMinutes', align: 'end' },
        { text: '狀態', value: 'status', align: 'center' },
        { text: '已紀錄筆數', value: 'recordedCount', align: 'end' },
        { text: '操作', value: 'actions', align: 'center', sortable: false },
      ],
      measurementScheduleStatus: {
        pending: '等待執行',
        complete: '完成',
        imcomplete: '未完成',
        failed: '失敗',
      },
    }),
    computed: {
      hasMeasurementPlanInExecution() {
        return _.isPlainObject(this.$store.state.patient.measurementPlanInExecution);
      },
      measurementSchedules() {
        const measurementSchedules = new Array();
        for (const schedule of this.$store.state.measurementSchedules) {
          const measurementSchedule = new Object();
          measurementSchedule.id = schedule.id;
          const beginAtDate = Date.withoutSeconds(schedule.beginAt.toDate());
          measurementSchedule.beginAt = beginAtDate.getTime();
          measurementSchedule.beginAtString = beginAtDate.shortLocalizedString;
          measurementSchedule.eachMeasurementTimes = schedule.eachMeasurement.times;
          measurementSchedule.eachMeasurementIntervalInMinutes =
            schedule.eachMeasurement.times > 1
              ? schedule.eachMeasurement.intervalInMinutes
              : '（無）';
          measurementSchedule.status = this.measurementScheduleStatus[schedule.status];
          measurementSchedule.recordedCount = Object.keys(schedule.measurementRecords).length;
          measurementSchedules.push(measurementSchedule);
        }
        measurementSchedules.sort((a, b) => a.beginAt - b.beginAt);
        measurementSchedules.forEach((v, i) => (v.number = i + 1));
        return measurementSchedules;
      },
    },
    watch: {
      addMeasurementScheduleDialogIsOpen(value) {
        if (!value) this.addMeasurementScheduleDialogKey += 1;
      },
      editMeasurementScheduleDialogIsOpen(value) {
        if (!value) this.editMeasurementScheduleDialogKey += 1;
      },
    },
    methods: {
      async beforeDeleteMeasurementSchedule(id) {
        this.messageDialogYesAction = () => this.deleteMeasurementSchedule(id);
        this.$_mixin_messageDialog_open(
          MessageDialogType.warning,
          '警告',
          '請問要刪除此量測排程嗎？',
          MessageDialogButtons.yesNo,
        );
      },
      async deleteMeasurementSchedule(id) {
        this.requesting = true;
        await firestore
          .collection('patients')
          .doc(this.$store.state.patientId)
          .collection('measurementSchedules')
          .doc(id)
          .delete()
          .catch((error) =>
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定'),
          );
        this.requesting = false;
        this.messageDialogYesAction = null;
      },
      close() {
        this.$_mixin_messageSnackbar_hide();
        this.$emit('input', false);
      },
    },
  }),
);
</script>

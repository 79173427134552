<template>
  <v-dialog
    class="measurement-select-plan-dialog"
    :value="value"
    persistent
    max-width="400px"
    @input="$emit('input', $event)"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <v-card>
      <v-toolbar color="primary" dark dense>
        <v-icon left>mdi-clipboard-text-play</v-icon>
        <v-toolbar-title>選擇量測計畫</v-toolbar-title>
        <v-spacer />
        <v-btn icon :disabled="requesting !== false" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-form v-model="isFormValid">
          <v-container>
            <v-row>
              <v-col>
                <v-select
                  v-model="measurementPlanInExecutionData.id"
                  dense
                  label="量測計畫"
                  :rules="[inputRules.required]"
                  :items="
                    $store.state.measurementPlans.map((v) => ({
                      value: v.id,
                      text: v.name,
                    }))
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-dialog
                  ref="pickBeginAtDialog"
                  v-model="pickBeginAtDialogIsOpen"
                  width="290px"
                  persistent
                  :return-value.sync="beginAtString"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="localizedBeginAtString"
                      dense
                      label="開始於"
                      readonly
                      v-bind="attrs"
                      :rules="[inputRules.required]"
                      v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-date-time-picker
                      :key="beginAtPickerKey"
                      v-model="beginAtString"
                      color="primary"
                      dark
                      full-width
                      scrollable
                    />
                    <v-divider />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="error" text @click="pickBeginAtDialogIsOpen = false">
                        取消
                      </v-btn>
                      <v-btn
                        color="primary"
                        depressed
                        :disabled="!beginAtString"
                        @click="$refs.pickBeginAtDialog.save(beginAtString)"
                      >
                        確定
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
      <v-divider />
      <v-card-actions>
        <v-btn
          depressed
          color="warning"
          :loading="requesting === 'clear'"
          :disabled="
            !_.isPlainObject($store.state.patient.measurementPlanInExecution) ||
            (requesting && requesting !== 'clear')
          "
          @click="clear()"
        >
          清除
        </v-btn>
        <v-spacer />
        <v-btn text :disabled="requesting !== false" color="error" @click="close()">取消</v-btn>
        <v-btn
          depressed
          color="primary"
          :loading="requesting === 'done'"
          :disabled="!isFormValid || (requesting && requesting !== 'done')"
          @click="done()"
        >
          完成
        </v-btn>
      </v-card-actions>
    </v-card>
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_isShowing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </v-dialog>
</template>

<script>
import Vue from 'vue';

import * as dateTimeString from '@/utils/dateTimeString';
import VDateTimePicker from '@/components/Vuetify/VDateTimePicker';
import extensions from '@/mixins/extensions';
import inputRules from '@/mixins/inputRules';
import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
import { Date } from '@/extensions';
import { firestore } from '@/firebase';
import { calculateMeasurementScheduleId } from '@/models/Measure';

export default Vue.component(
  'measurement-select-plan-dialog',
  Vue.extend({
    name: 'MeasurementSelectPlanDialog',
    components: { VDateTimePicker },
    mixins: [inputRules, extensions, messageSnackbar],
    props: {
      value: { type: Boolean, default: true },
    },
    data: () => ({
      requesting: false,
      isFormValid: false,
      pickBeginAtDialogIsOpen: false,
      beginAtPickerKey: 0,
      measurementPlanInExecutionData: null,
      measurementPlanInExecutionDataTemplate: {
        beginAt: null,
        id: null,
        // ...measurementPlan,
      },
      measurementSchedulesData: null,
    }),
    computed: {
      beginAtString: {
        get() {
          if (!(this.measurementPlanInExecutionData.beginAt instanceof Date)) return null;
          return dateTimeString.fromDate(this.measurementPlanInExecutionData.beginAt);
        },
        set(value) {
          this.measurementPlanInExecutionData.beginAt = _.isString(value)
            ? dateTimeString.toDate(value)
            : null;
        },
      },
      localizedBeginAtString() {
        if (!(this.measurementPlanInExecutionData.beginAt instanceof Date)) return null;
        return this.measurementPlanInExecutionData.beginAt.longLocalizedString;
      },
    },
    watch: {
      pickBeginAtDialogIsOpen(value) {
        if (!value) this.beginAtPickerKey += 1;
      },
    },
    created() {
      this.measurementPlanInExecutionData = _.cloneDeep(
        _.isPlainObject(this.$store.state.patient.measurementPlanInExecution)
          ? this.$store.state.patient.measurementPlanInExecution
          : this.measurementPlanInExecutionDataTemplate,
      );
      if (
        _.isObject(this.measurementPlanInExecutionData.beginAt) &&
        !(this.measurementPlanInExecutionData.beginAt instanceof Date)
      ) {
        this.measurementPlanInExecutionData.beginAt = Date.withoutSeconds(
          this.measurementPlanInExecutionData.beginAt.toDate(),
        );
      } else if (_.isNil(this.measurementPlanInExecutionData.beginAt)) {
        this.measurementPlanInExecutionData.beginAt = Date.withoutSeconds();
      }
    },
    methods: {
      setMeasurementSchedulesData() {
        this.measurementSchedulesData = new Object();
        const epochRangeFrom = this.measurementPlanInExecutionData.beginAt.getTime();
        const epochRangeTo = (() => {
          const to = Date.withoutSeconds(this.measurementPlanInExecutionData.beginAt);
          to.setDate(to.getDate() + this.measurementPlanInExecutionData.plan.days);
          return to.getTime();
        })();
        for (let i = 0; i <= this.measurementPlanInExecutionData.plan.days; i++) {
          for (const set of this.measurementPlanInExecutionData.plan.sets) {
            const eachMeasurement =
              set.eachMeasurement ?? this.measurementPlanInExecutionData.plan.eachMeasurement;
            if (this.measurementPlanInExecutionData.type === 'period') {
              const fromHour = set.fromHour;
              const toHour = set.fromHour < set.toHour ? set.toHour : 24 + set.toHour;
              for (let j = fromHour * 60; j < toHour * 60; j += set.intervalInMinutes) {
                const t = j < 24 * 60 ? j : j - 24 * 60;
                const beginAt = Date.withoutSeconds(this.measurementPlanInExecutionData.beginAt);
                beginAt.setDate(beginAt.getDate() + i);
                beginAt.setHours(0, t);
                this.addMeasurementSchedule(beginAt, eachMeasurement, epochRangeFrom, epochRangeTo);
              }
            } else if (this.measurementPlanInExecutionData.type === 'fixed') {
              const beginAt = Date.withoutSeconds(this.measurementPlanInExecutionData.beginAt);
              beginAt.setDate(beginAt.getDate() + i);
              beginAt.setHours(
                Math.trunc(set.elapsedTimeInMinutes / 60),
                set.elapsedTimeInMinutes % 60,
              );
              this.addMeasurementSchedule(beginAt, eachMeasurement, epochRangeFrom, epochRangeTo);
            }
          }
        }
      },
      addMeasurementSchedule(beginAt, eachMeasurement, epochRangeFrom, epochRangeTo) {
        const epoch = beginAt.getTime();
        if (epoch >= epochRangeFrom && epoch < epochRangeTo) {
          const id = calculateMeasurementScheduleId({ beginAt, eachMeasurement });
          this.measurementSchedulesData[id] = {
            beginAt: Date.withoutSeconds(beginAt),
            eachMeasurement: eachMeasurement,
            status: 'pending',
            measurementRecords: new Object(),
          };
        }
      },
      async clearMeasurementSchedules() {
        const patient = firestore.collection('patients').doc(this.$store.state.patientId);
        await patient.update({ measurementPlanInExecution: null });
        const measurementSchedules = patient.collection('measurementSchedules');
        let i = 0;
        let batch = firestore.batch();
        for (const measurementSchedule of this.$store.state.measurementSchedules) {
          batch.delete(measurementSchedules.doc(measurementSchedule.id));
          i += 1;
          // FIXME: 500 is the firestore limit
          if (i % 500 === 0) {
            await batch.commit();
            batch = firestore.batch();
          }
        }
        await batch.commit();
      },
      async addMeasurementSchedules() {
        const patient = firestore.collection('patients').doc(this.$store.state.patientId);
        const measurementSchedules = patient.collection('measurementSchedules');
        let i = 0;
        let batch = firestore.batch();
        for (const [id, measurementSchedule] of Object.entries(this.measurementSchedulesData)) {
          batch.set(measurementSchedules.doc(id), measurementSchedule);
          i += 1;
          // FIXME: 500 is the firestore limit
          if (i % 500 === 0) {
            await batch.commit();
            batch = firestore.batch();
          }
        }
        await batch.commit();
        await patient.update({
          measurementPlanInExecution: this.measurementPlanInExecutionData,
        });
      },
      async done() {
        this.requesting = 'done';
        const selectedPlan = this.$store.state.measurementPlans.filter(
          (v) => v.id === this.measurementPlanInExecutionData.id,
        )[0];
        Object.assign(this.measurementPlanInExecutionData, selectedPlan);
        this.setMeasurementSchedulesData();
        await this.clearMeasurementSchedules()
          .then(() => this.addMeasurementSchedules())
          .then(() => this.close())
          .catch((error) =>
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定'),
          );
        this.requesting = false;
      },
      async clear() {
        this.requesting = 'clear';
        await this.clearMeasurementSchedules()
          .then(() => this.close())
          .catch((error) =>
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定'),
          );
        this.requesting = false;
      },
      close() {
        this.$emit('input', false);
      },
    },
  }),
);
</script>

<template>
  <v-dialog
    class="measurement-manage-plan-dialog"
    :value="value"
    :persistent="requesting"
    fullscreen
    transition="dialog-bottom-transition"
    @input="$emit('input', $event)"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <v-card>
      <v-toolbar color="primary" dark dense style="z-index: 1">
        <v-icon left>mdi-clipboard-text-multiple</v-icon>
        <v-toolbar-title>量測計畫管理</v-toolbar-title>
        <v-spacer />
        <v-btn icon :disabled="requesting" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        height="calc(100vh - 96px)"
        fixed-header
        hide-default-footer
        :items="measurementPlans"
        :items-per-page="-1"
        :headers="measurementPlansDataTableHeaders"
      >
        <template #top>
          <v-toolbar flat dense>
            <v-toolbar-title>量測計畫</v-toolbar-title>
            <v-spacer />
            <div class="ma-n3">
              <measurement-add-or-edit-plan-dialog
                :key="addMeasurementPlanDialogKey"
                v-model="addMeasurementPlanDialogIsOpen"
              >
                <template #activator="{ on: onDialog, attrs: attrsDialog }">
                  <v-tooltip bottom>
                    <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
                      <v-btn
                        :rounded="!$vuetify.breakpoint.mobile"
                        :text="!$vuetify.breakpoint.mobile"
                        :outlined="!$vuetify.breakpoint.mobile"
                        :icon="$vuetify.breakpoint.mobile"
                        color="primary"
                        v-bind="{ ...attrsDialog, ...attrsTooltip }"
                        v-on="{ ...onDialog, ...onTooltip }"
                      >
                        <v-icon :left="!$vuetify.breakpoint.mobile">mdi-plus</v-icon>
                        <template v-if="!$vuetify.breakpoint.mobile">新增</template>
                      </v-btn>
                    </template>
                    <span>新增</span>
                  </v-tooltip>
                </template>
              </measurement-add-or-edit-plan-dialog>
            </div>
          </v-toolbar>
        </template>
        <template #[`item.actions`]="{ item }">
          <div style="min-width: 72px" class="ma-n2">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="
                    editingMeasurementPlanId = item.id;
                    editMeasurementPlanDialogIsOpen = true;
                  "
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>編輯量測計畫</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="beforeDeleteMeasurementPlan(item.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>刪除量測計畫</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <v-fade-transition>
        <v-overlay v-model="requesting" absolute>
          <v-progress-circular indeterminate size="32" />
        </v-overlay>
      </v-fade-transition>
    </v-card>
    <measurement-add-or-edit-plan-dialog
      v-if="_.isString(editingMeasurementPlanId)"
      :id="editingMeasurementPlanId"
      :key="editMeasurementPlanDialogKey"
      v-model="editMeasurementPlanDialogIsOpen"
      @input="$event ? null : (editingMeasurementPlanId = null)"
    />
    <message-dialog
      v-model="$data.$_mixin_messageDialog_isOpen"
      :type="$data.$_mixin_messageDialog_type"
      :title="$data.$_mixin_messageDialog_title"
      :message="$data.$_mixin_messageDialog_message"
      :yes-button="$data.$_mixin_messageDialog_yesButton"
      :no-button="$data.$_mixin_messageDialog_noButton"
      :cancel-button="$data.$_mixin_messageDialog_cancelButton"
      :persistent="$data.$_mixin_messageDialog_persistent"
      @yes="messageDialogYesAction()"
    />
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_isShowing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </v-dialog>
</template>

<script>
import Vue from 'vue';
// import firebase from 'firebase/app';

import MeasurementAddOrEditPlanDialog from '@/components/App/Measurement/MeasurementAddOrEditPlanDialog';
import extensions from '@/mixins/extensions';
import messageDialog, { MessageDialogType, MessageDialogButtons } from '@/mixins/messageDialog';
import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
import { firestore } from '@/firebase';
import { calculateMaxMeasurementTimes } from '@/models/Measure';

export default Vue.component(
  'measurement-manage-plan-dialog',
  Vue.extend({
    name: 'MeasurementManagePlanDialog',
    components: { MeasurementAddOrEditPlanDialog },
    mixins: [extensions, messageDialog, messageSnackbar],
    props: {
      value: { type: Boolean, default: true },
    },
    data: () => ({
      requesting: false,
      messageDialogYesAction: null,
      addMeasurementPlanDialogKey: 0,
      addMeasurementPlanDialogIsOpen: false,
      editMeasurementPlanDialogKey: 0,
      editMeasurementPlanDialogIsOpen: false,
      editingMeasurementPlanId: null,
      deletingMeasurementPlanId: null,
      measurementPlansDataTableHeaders: [
        { text: '名稱', value: 'name', align: 'center' },
        { text: '種類', value: 'type', align: 'center' },
        { text: '計畫天數', value: 'planDays', align: 'end' },
        { text: '計畫組數', value: 'planSets', align: 'end' },
        { text: '預估最大量測次數', value: 'maxMeasurementTimes', align: 'end' },
        { text: '操作', value: 'actions', align: 'center', sortable: false },
      ],
    }),
    computed: {
      measurementPlans() {
        const measurementPlans = new Array();
        for (const plan of this.$store.state.measurementPlans) {
          const measurementPlan = new Object();
          measurementPlan.id = plan.id;
          measurementPlan.name = plan.name;
          measurementPlan.type = { period: '週期時間', fixed: '固定時間' }[plan.type];
          measurementPlan.planDays = plan.plan.days;
          measurementPlan.planSets = plan.plan.sets.length;
          measurementPlan.maxMeasurementTimes = calculateMaxMeasurementTimes(plan);
          measurementPlans.push(measurementPlan);
        }
        return measurementPlans;
      },
    },
    watch: {
      addMeasurementPlanDialogIsOpen(value) {
        if (!value) this.addMeasurementPlanDialogKey += 1;
      },
      editMeasurementPlanDialogIsOpen(value) {
        if (!value) this.editMeasurementPlanDialogKey += 1;
      },
    },
    methods: {
      async beforeDeleteMeasurementPlan(id) {
        this.messageDialogYesAction = () => this.deleteMeasurementPlan(id);
        this.$_mixin_messageDialog_open(
          MessageDialogType.warning,
          '警告',
          '請問要刪除此量測計畫嗎？',
          MessageDialogButtons.yesNo,
        );
      },
      async deleteMeasurementPlan(id) {
        this.requesting = true;
        await firestore
          .collection('users')
          .doc(this.$store.state.profile.uid)
          .collection('measurementPlans')
          .doc(id)
          .delete()
          .catch((error) =>
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定'),
          );
        this.requesting = false;
        this.messageDialogYesAction = null;
      },
      close() {
        this.$_mixin_messageSnackbar_hide();
        this.$emit('input', false);
      },
    },
  }),
);
</script>

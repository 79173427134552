<template>
  <v-dialog
    class="measurement-add-or-edit-plan-dialog"
    :value="value"
    persistent
    max-width="720px"
    @input="$emit('input', $event)"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <v-card>
      <v-toolbar color="primary" dark dense>
        <template v-if="_.isString(id)">
          <v-icon left>mdi-clipboard-edit</v-icon>
          <v-toolbar-title>
            編輯量測計畫—{{ $store.state.measurementPlans.filter((v) => v.id === id)[0].name }}
          </v-toolbar-title>
        </template>
        <template v-else>
          <v-icon left>mdi-clipboard-plus</v-icon>
          <v-toolbar-title>
            新增量測計畫{{
              measurementPlanData.name && measurementPlanData.name.trim()
                ? `—${measurementPlanData.name.trim()}`
                : ''
            }}
          </v-toolbar-title>
        </template>
        <v-spacer />
        <v-btn icon :disabled="requesting" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-window v-model="window" touchless>
        <v-window-item value="selectType">
          <v-card-subtitle class="pb-0">請選擇種類：</v-card-subtitle>
          <v-list dense>
            <v-list-item @click="setMeasurementPlanType('period')">
              <v-list-item-content>
                <v-list-item-title>週期時間</v-list-item-title>
                <v-list-item-subtitle>
                  設置每一天中，各個時間範圍需要每隔多久執行一次量測。
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="setMeasurementPlanType('fixed')">
              <v-list-item-content>
                <v-list-item-title>固定時間</v-list-item-title>
                <v-list-item-subtitle>
                  設置每一天中，有哪些時間點需要執行量測。
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <template v-if="!_.isString(id)">
            <v-card-subtitle class="pb-0">快速設置：</v-card-subtitle>
            <v-list dense>
              <v-list-item
                :disabled="!canDayNightPeriodMeasurementPlanTypeSet"
                @click="setMeasurementPlanType('period.dayNight')"
              >
                <v-list-item-content>
                  <v-list-item-title>日夜週期</v-list-item-title>
                  <v-list-item-subtitle>
                    設置每一天中，日間和夜間範圍個別需要每隔多久執行一回量測。
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="setMeasurementPlanType('fixed.722')">
                <v-list-item-content>
                  <v-list-item-title>722</v-list-item-title>
                  <v-list-item-subtitle>
                    總共7天，每天執行2回，每回量測2次間隔15分鐘。
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-window-item>
        <v-window-item value="configPlan">
          <v-container fluid class="pb-0">
            <v-form v-model="isFormValid">
              <v-container fluid>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      label="種類"
                      readonly
                      hide-details
                      :value="measurementPlanTypeString"
                      append-icon="mdi-pencil"
                      @click:append="beforeSelectMeasurementPlanType()"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="measurementPlanData.name"
                      clearable
                      dense
                      label="名稱"
                      :rules="[inputRules.required]"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model.number="measurementPlanData.plan.days"
                      dense
                      label="天數"
                      suffix="天"
                      type="number"
                      :rules="[inputRules.required, inputRules.integerRange(1, 7).within]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model.number="measurementPlanData.plan.eachMeasurement.times"
                      dense
                      label="每回的量測次數"
                      suffix="次"
                      type="number"
                      :rules="[inputRules.required, inputRules.integerRange(1, 10).within]"
                    />
                  </v-col>
                  <v-col v-if="measurementPlanData.plan.eachMeasurement.times !== 1" cols="6">
                    <v-text-field
                      v-model.number="measurementPlanData.plan.eachMeasurement.intervalInMinutes"
                      dense
                      label="每回的量測間隔"
                      suffix="分鐘"
                      type="number"
                      :rules="[inputRules.required, inputRules.integerRange(0, 10).within]"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <template v-if="_.isString(measurementPlanSubType)">
                <v-divider />
                <v-container fluid>
                  <v-row v-if="measurementPlanSubType === 'dayNight'">
                    <v-col cols="6">
                      <v-text-field
                        v-model.number="measurementPlanData.plan.sets[0].intervalInMinutes"
                        dense
                        label="日間時段的量測間隔"
                        suffix="分鐘"
                        type="number"
                        :rules="[inputRules.required, inputRules.integerRange(1, 1439).within]"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model.number="measurementPlanData.plan.sets[1].intervalInMinutes"
                        dense
                        label="夜間時段的量測間隔"
                        suffix="分鐘"
                        type="number"
                        :rules="[inputRules.required, inputRules.integerRange(1, 1439).within]"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-else-if="measurementPlanSubType === '722'">
                    <v-col
                      v-for="(picker, i) in elapsedTimeInMinutesPickersFor722"
                      :key="`elapsedTimeInMinutesPickerFor722-${i}`"
                      cols="6"
                    >
                      <v-menu
                        :ref="`pickElapsedTimeInMinutesMenuFor722`"
                        v-model="picker.isOpen"
                        :close-on-content-click="false"
                        :return-value.sync="picker.string"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="picker.localizedString"
                            dense
                            :label="`第${i + 1}組開始於`"
                            readonly
                            v-bind="attrs"
                            :rules="[inputRules.required]"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          :key="picker.key"
                          v-model="picker.string"
                          ampm-in-title
                          scrollable
                        >
                          <v-spacer />
                          <v-btn
                            text
                            color="error"
                            @click="pickElapsedTimeInMinutesMenuFor722Cancel(picker)"
                          >
                            取消
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            :disabled="!picker.string"
                            @click="
                              pickElapsedTimeInMinutesMenuFor722Ok(
                                picker,
                                $refs.pickElapsedTimeInMinutesMenuFor722[i],
                              )
                            "
                          >
                            確定
                          </v-btn>
                        </v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-form>
          </v-container>
          <template v-if="!_.isString(measurementPlanSubType)">
            <v-divider />
            <v-data-table
              height="240px"
              hide-default-footer
              :footer-props="{ showCurrentPage: true, showFirstLastPage: true }"
              :items="measurementPlanDataPlanSets"
              :items-per-page="-1"
              :item-class="(item) => (item.isOverlapped ? 'red lighten-4' : '')"
              :headers="measurementPlanSetsDataTableHeaders[measurementPlanData.type]"
            >
              <template #top>
                <v-toolbar flat dense>
                  <v-toolbar-title>量測組</v-toolbar-title>
                  <v-spacer />
                  <div class="ma-n3">
                    <measurement-add-or-edit-plan-set-dialog
                      :key="addMeasurementPlanSetDialogKey"
                      v-model="addMeasurementPlanSetDialogIsOpen"
                      :type="measurementPlanData.type"
                      @set="measurementPlanData.plan.sets.push($event)"
                    >
                      <template #activator="{ on: onDialog, attrs: attrsDialog }">
                        <v-tooltip bottom>
                          <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
                            <v-btn
                              :rounded="!$vuetify.breakpoint.mobile"
                              :text="!$vuetify.breakpoint.mobile"
                              :outlined="!$vuetify.breakpoint.mobile"
                              :icon="$vuetify.breakpoint.mobile"
                              class="ml-1"
                              color="primary"
                              v-bind="{ ...attrsDialog, ...attrsTooltip }"
                              v-on="{ ...onDialog, ...onTooltip }"
                            >
                              <v-icon :left="!$vuetify.breakpoint.mobile">mdi-plus</v-icon>
                              <template v-if="!$vuetify.breakpoint.mobile">新增</template>
                            </v-btn>
                          </template>
                          <span>新增</span>
                        </v-tooltip>
                      </template>
                    </measurement-add-or-edit-plan-set-dialog>
                  </div>
                </v-toolbar>
              </template>
              <template #[`item.fromHour`]="{ item }">
                {{ item.timeRangeString }}
              </template>
              <template #[`item.beginAt`]="{ item }">
                {{ item.beginAtString }}
              </template>
              <template #[`item.actions`]="{ item }">
                <div style="min-width: 72px" class="ma-n2">
                  <v-btn
                    icon
                    color="success"
                    @click.stop="
                      editingMeasurementPlanSetIndex = item.number - 1;
                      editMeasurementPlanSetDialogIsOpen = true;
                    "
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon color="error" @click="deleteMeasurementPlanSet(item.number - 1)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </template>
        </v-window-item>
      </v-window>
      <v-divider />
      <v-card-actions>
        <template v-if="window === 'configPlan'">
          <span v-if="!_.isNumber(maxMeasurementTimes)" :class="`ma-2 body-2 error--text`">
            無法計算預估最大量測次數（上限140次）
          </span>
          <span v-else-if="maxMeasurementTimes > 140" :class="`ma-2 body-2 warning--text`">
            預估最大量測{{ maxMeasurementTimes }}次，已超出上限（上限140次）
          </span>
          <span v-else :class="`ma-2 body-2`">
            預估最大量測{{ maxMeasurementTimes }}次（上限140次）
          </span>
        </template>
        <v-spacer />
        <v-btn
          color="primary"
          depressed
          :loading="requesting"
          :disabled="!isFormValid || !isPlanSetsValid"
          @click="done()"
        >
          完成
        </v-btn>
      </v-card-actions>
    </v-card>
    <measurement-add-or-edit-plan-set-dialog
      v-if="_.isNumber(editingMeasurementPlanSetIndex)"
      v-model="editMeasurementPlanSetDialogIsOpen"
      :type="measurementPlanData.type"
      :set="measurementPlanData.plan.sets[editingMeasurementPlanSetIndex]"
      @set="Object.assign(measurementPlanData.plan.sets[editingMeasurementPlanSetIndex], $event)"
    />
    <message-dialog
      v-model="$data.$_mixin_messageDialog_isOpen"
      :type="$data.$_mixin_messageDialog_type"
      :title="$data.$_mixin_messageDialog_title"
      :message="$data.$_mixin_messageDialog_message"
      :yes-button="$data.$_mixin_messageDialog_yesButton"
      :no-button="$data.$_mixin_messageDialog_noButton"
      :cancel-button="$data.$_mixin_messageDialog_cancelButton"
      :persistent="$data.$_mixin_messageDialog_persistent"
      @yes="messageDialogYesAction()"
    />
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_isShowing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </v-dialog>
</template>

<script>
import Vue from 'vue';

import MeasurementAddOrEditPlanSetDialog from '@/components/App/Measurement/MeasurementAddOrEditPlanSetDialog';
import extensions from '@/mixins/extensions';
import inputRules from '@/mixins/inputRules';
import messageDialog, { MessageDialogType, MessageDialogButtons } from '@/mixins/messageDialog';
import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
import { Date } from '@/extensions';
import { firestore } from '@/firebase';
import { calculateMaxMeasurementTimes } from '@/models/Measure';

export default Vue.component(
  'measurement-add-or-edit-plan-dialog',
  Vue.extend({
    name: 'MeasurementAddOrEditPlanDialog',
    components: { MeasurementAddOrEditPlanSetDialog },
    mixins: [inputRules, extensions, messageDialog, messageSnackbar],
    props: {
      value: { type: Boolean, default: true },
      id: { type: String, default: null },
    },
    data: () => ({
      window: null,
      isFormValid: false,
      requesting: false,
      messageDialogYesAction: null,
      addMeasurementPlanSetDialogIsOpen: false,
      addMeasurementPlanSetDialogKey: 0,
      editMeasurementPlanSetDialogIsOpen: false,
      editMeasurementPlanSetDialogKey: 0,
      editingMeasurementPlanSetIndex: null,
      elapsedTimeInMinutesPickersFor722: [],
      elapsedTimeInMinutesPickerFor722Template: {
        isOpen: false,
        key: 0,
        string: null,
        lastString: null,
        localizedString: null,
      },
      measurementPlanSubType: null,
      measurementPlanData: null,
      measurementPlanDataTemplate: {
        type: null,
        name: null,
        plan: {
          days: null,
          sets: [],
          eachMeasurement: {
            times: null,
            intervalInMinutes: null,
          },
        },
      },
      measurementPlanSetsDataTableHeaders: {
        period: [
          { text: '#', value: 'number', align: 'end', sortable: false },
          { text: '時間範圍', value: 'fromHour', align: 'center' },
          { text: '間隔（分鐘）', value: 'intervalInMinutes', align: 'end' },
          { text: '最大量測回數', value: 'maxMeasurements', align: 'end' },
          { text: '操作', value: 'actions', align: 'center', sortable: false },
        ],
        fixed: [
          { text: '#', value: 'number', align: 'end', sortable: false },
          { text: '開始於', value: 'beginAt', align: 'center' },
          { text: '操作', value: 'actions', align: 'center', sortable: false },
        ],
      },
    }),
    computed: {
      canDayNightPeriodMeasurementPlanTypeSet() {
        const bloodPressureGoals = this.$store.state.patient.bloodPressureGoals;
        return (
          _.isNumber(bloodPressureGoals.daytime.beginHour) &&
          _.isNumber(bloodPressureGoals.daytime.beginHour)
        );
      },
      measurementPlanTypeString() {
        const planTypeString = { period: '週期時間', fixed: '固定時間' }[
          this.measurementPlanData.type
        ];
        const planSubTypeString = { dayNight: '日夜週期', 722: '722' }[this.measurementPlanSubType];
        return _.isString(this.measurementPlanSubType)
          ? planSubTypeString + `（${planTypeString}）`
          : planTypeString;
      },
      measurementPlanDataPlanSets() {
        const measurementPlanDataPlanSets = Array.from({
          length: this.measurementPlanData.plan.sets.length,
        });
        const periodUsedHours = new Array(24).fill(false);
        const fixedBeginAts = new Array();
        for (const [i, set] of this.measurementPlanData.plan.sets.entries()) {
          measurementPlanDataPlanSets[i] = new Object();
          measurementPlanDataPlanSets[i].number = i + 1;
          measurementPlanDataPlanSets[i].isOverlapped = false;
          if (this.measurementPlanData.type === 'period') {
            measurementPlanDataPlanSets[i].fromHour = set.fromHour;
            measurementPlanDataPlanSets[i].timeRangeString = this.getTimeRangeString(
              set.fromHour,
              set.toHour,
            );
            measurementPlanDataPlanSets[i].intervalInMinutes = set.intervalInMinutes;
            const fromHour = set.fromHour;
            const toHour = set.fromHour < set.toHour ? set.toHour : 24 + set.toHour;
            measurementPlanDataPlanSets[i].maxMeasurements = Math.floor(
              ((toHour - fromHour) * 60) / set.intervalInMinutes,
            );
            for (let j = fromHour; j < toHour; j++) {
              if (periodUsedHours[j < 24 ? j : j - 24]) {
                measurementPlanDataPlanSets[i].isOverlapped = true;
                break;
              }
            }
            if (!measurementPlanDataPlanSets[i].isOverlapped) {
              for (let j = fromHour; j < toHour; j++) periodUsedHours[j < 24 ? j : j - 24] = true;
            }
          } else if (this.measurementPlanData.type === 'fixed') {
            measurementPlanDataPlanSets[i].beginAt = set.elapsedTimeInMinutes;
            measurementPlanDataPlanSets[i].beginAtString =
              Date.localizedHourMinuteStrings[set.elapsedTimeInMinutes];
            if (fixedBeginAts.includes(set.elapsedTimeInMinutes))
              measurementPlanDataPlanSets[i].isOverlapped = true;
            else fixedBeginAts.push(set.elapsedTimeInMinutes);
          }
        }
        return measurementPlanDataPlanSets;
      },
      maxMeasurementTimes() {
        if (
          !_.isNumber(this.measurementPlanData.plan.days) ||
          !_.isNumber(this.measurementPlanData.plan.eachMeasurement.times) ||
          (this.measurementPlanData.plan.eachMeasurement.times > 1 &&
            !_.isNumber(this.measurementPlanData.plan.eachMeasurement.intervalInMinutes))
        ) {
          return null;
        } else if (_.isString(this.measurementPlanSubType)) {
          const sets = this.measurementPlanData.plan.sets;
          if (
            this.measurementPlanSubType === '722' &&
            (!_.isNumber(sets[0].elapsedTimeInMinutes) ||
              !_.isNumber(sets[1].elapsedTimeInMinutes) ||
              sets[0].elapsedTimeInMinutes === sets[1].elapsedTimeInMinutes)
          ) {
            return null;
          } else if (
            this.measurementPlanSubType === 'dayNight' &&
            (!_.isNumber(sets[0].intervalInMinutes) || !_.isNumber(sets[0].intervalInMinutes))
          ) {
            return null;
          }
        }
        const calculated = calculateMaxMeasurementTimes(this.measurementPlanData);
        return _.isSafeInteger(calculated) ? calculated : null;
      },
      isPlanSetsValid() {
        return (
          this.measurementPlanDataPlanSets.length > 0 &&
          !this.measurementPlanDataPlanSets.map((v) => v.isOverlapped).includes(true) &&
          _.isNumber(this.maxMeasurementTimes)
        );
      },
    },
    watch: {
      addMeasurementPlanSetDialogIsOpen(value) {
        if (!value) this.addMeasurementPlanSetDialogKey += 1;
      },
      editMeasurementPlanSetDialogIsOpen(value) {
        if (!value) this.editMeasurementPlanSetDialogKey += 1;
      },
      ['measurementPlanData.plan.eachMeasurement.times'](value) {
        if (value === 1) this.measurementPlanData.plan.eachMeasurement.intervalInMinutes = null;
      },
    },
    created() {
      this.measurementPlanData = _.cloneDeep(
        _.isString(this.id)
          ? this.$store.state.measurementPlans.filter((v) => v.id === this.id)[0]
          : this.measurementPlanDataTemplate,
      );
      this.window = this.id === null ? 'selectType' : 'configPlan';
    },
    methods: {
      pickElapsedTimeInMinutesMenuFor722Ok(picker, ref) {
        const set = this.measurementPlanData.plan.sets[picker.index];
        picker.lastString = picker.string;
        const [hourString, minuteString] = picker.string.split(':');
        set.elapsedTimeInMinutes = parseInt(hourString) * 60 + parseInt(minuteString);
        picker.localizedString = Date.localizedHourMinuteStrings[set.elapsedTimeInMinutes];
        ref.save(picker.string);
        picker.key += 1;
      },
      pickElapsedTimeInMinutesMenuFor722Cancel(picker) {
        const set = this.measurementPlanData.plan.sets[picker.index];
        picker.string = picker.lastString;
        const [hourString, minuteString] = picker.string.split(':');
        set.elapsedTimeInMinutes = parseInt(hourString) * 60 + parseInt(minuteString);
        picker.isOpen = false;
        picker.key += 1;
      },
      getTimeRangeString(from, to) {
        const localized = Date.localizedHourStrings;
        if (from < to) return `${localized[from]}～${localized[to]}`;
        else return `${localized[from]}～隔天${localized[to]}`;
      },
      setMeasurementPlanType(type) {
        const [mainType, subType] = type.split('.');
        if (this.measurementPlanData.type !== mainType || this.measurementPlanSubType !== subType) {
          this.measurementPlanData.plan.sets.splice(0);
          this.elapsedTimeInMinutesPickersFor722.splice(0);
          this.measurementPlanData.type = mainType;
          this.measurementPlanSubType = subType ?? null;
          if (_.isString(subType)) {
            if (subType === 'dayNight') {
              const hours = [
                this.$store.state.patient.bloodPressureGoals.daytime.beginHour,
                this.$store.state.patient.bloodPressureGoals.nighttime.beginHour,
              ];
              this.measurementPlanData.plan.sets.push(
                {
                  fromHour: hours[0],
                  toHour: hours[1],
                  intervalInMinutes: null,
                  eachMeasurement: null,
                },
                {
                  fromHour: hours[1],
                  toHour: hours[0],
                  intervalInMinutes: null,
                  eachMeasurement: null,
                },
              );
            } else if (subType === '722') {
              this.elapsedTimeInMinutesPickersFor722 = [
                { index: 0, ...this.elapsedTimeInMinutesPickerFor722Template },
                { index: 1, ...this.elapsedTimeInMinutesPickerFor722Template },
              ];
              this.measurementPlanData.plan.days = 7;
              this.measurementPlanData.plan.sets.push(
                {
                  elapsedTimeInMinutes: null,
                  eachMeasurement: null,
                },
                {
                  elapsedTimeInMinutes: null,
                  eachMeasurement: null,
                },
              );
              this.measurementPlanData.plan.eachMeasurement.times = 2;
              this.measurementPlanData.plan.eachMeasurement.intervalInMinutes = 15;
            }
          }
        }
        this.window = 'configPlan';
      },
      beforeSelectMeasurementPlanType() {
        this.messageDialogYesAction = () => (this.window = 'selectType');
        this.$_mixin_messageDialog_open(
          MessageDialogType.warning,
          '警告',
          '變更量測計畫的種類會清除所有量測組，請問是否要繼續？',
          MessageDialogButtons.yesNo,
        );
      },
      deleteMeasurementPlanSet(index) {
        this.measurementPlanData.plan.sets.splice(index, 1);
      },
      generateRandomHash(length) {
        return Array.from({ length: length })
          .map(() => Math.trunc(Math.random() * 16).toString(16))
          .join('');
      },
      async done() {
        this.requesting = true;
        const id = this.id ?? this.generateRandomHash(32);
        await firestore
          .collection('users')
          .doc(this.$store.state.profile.uid)
          .collection('measurementPlans')
          .doc(id)
          .set(this.measurementPlanData)
          .then(() => this.close())
          .catch((error) =>
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定'),
          );
        this.requesting = false;
      },
      close() {
        this.$_mixin_messageSnackbar_hide();
        this.$emit('input', false);
      },
    },
  }),
);
</script>

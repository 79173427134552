import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations, firestoreAction } from 'vuexfire';

import { firestore } from '@/firebase';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    app: {
      name: process.env.VUE_APP_NAME,
      icon: process.env.VUE_APP_ICON,
      themeColor: process.env.VUE_APP_THEME_COLOR,
      package: {
        version: process.env.VUE_APP_PACKAGE_VERSION,
        author: process.env.VUE_APP_PACKAGE_AUTHOR,
        contributors: process.env.VUE_APP_PACKAGE_CONTRIBUTORS,
      },
    },
    globalOptions: null,
    profile: null,
    user: null,
    measurementPlans: null,
    patientId: null,
    patient: null,
    measurementSchedules: null,
    measurementRecords: null,
  },
  mutations: {
    sign(state, user) {
      state.profile = _.isObject(user)
        ? {
            uid: user.uid,
            name: user.displayName,
            email: user.email,
            photoUrl: user.photoURL,
          }
        : null;
    },
    patientId(state, patientId) {
      state.patientId = patientId;
    },
    ...vuexfireMutations,
  },
  actions: {
    bindGlobalOptions: firestoreAction((context) =>
      context.bindFirestoreRef('globalOptions', firestore.collection('options').doc('global')),
    ),
    unbindGlobalOptions: firestoreAction((context) =>
      context.unbindFirestoreRef('globalOptions', firestore.collection('options').doc('global')),
    ),
    bindUser: firestoreAction((context, userId) =>
      context.bindFirestoreRef('user', firestore.collection('users').doc(userId)),
    ),
    unbindUser: firestoreAction((context, userId) =>
      context.unbindFirestoreRef('user', firestore.collection('users').doc(userId)),
    ),
    bindMeasurementPlans: firestoreAction((context, userId) =>
      context.bindFirestoreRef(
        'measurementPlans',
        firestore.collection('users').doc(userId).collection('measurementPlans'),
      ),
    ),
    unbindMeasurementPlans: firestoreAction((context, userId) =>
      context.unbindFirestoreRef(
        'measurementPlans',
        firestore.collection('users').doc(userId).collection('measurementPlans'),
      ),
    ),
    bindPatient: firestoreAction((context, patientId) =>
      context.bindFirestoreRef('patient', firestore.collection('patients').doc(patientId)),
    ),
    unbindPatient: firestoreAction((context, patientId) =>
      context.unbindFirestoreRef('patient', firestore.collection('patients').doc(patientId)),
    ),
    bindMeasurementSchedules: firestoreAction((context, patientId) =>
      context.bindFirestoreRef(
        'measurementSchedules',
        firestore
          .collection('patients')
          .doc(patientId)
          .collection('measurementSchedules')
          .orderBy('beginAt', 'asc'),
      ),
    ),
    unbindMeasurementSchedules: firestoreAction((context, patientId) =>
      context.unbindFirestoreRef(
        'measurementSchedules',
        firestore.collection('patients').doc(patientId).collection('measurementSchedules'),
      ),
    ),
    bindMeasurementRecords: firestoreAction((context, patientId) =>
      context.bindFirestoreRef(
        'measurementRecords',
        firestore
          .collection('patients')
          .doc(patientId)
          .collection('measurementRecords')
          .orderBy('measuredAt', 'desc'),
      ),
    ),
    unbindMeasurementRecords: firestoreAction((context, patientId) =>
      context.unbindFirestoreRef(
        'measurementRecords',
        firestore.collection('patients').doc(patientId).collection('measurementRecords'),
      ),
    ),
  },
  modules: {},
});

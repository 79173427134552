<template>
  <v-dialog
    class="edit-blood-pressure-goals-dialog"
    :value="value"
    persistent
    max-width="640px"
    @input="$emit('input', $event)"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <v-card>
      <v-toolbar color="primary" dark dense style="z-index: 2">
        <v-icon left>mdi-water-check</v-icon>
        <v-toolbar-title>編輯血壓目標</v-toolbar-title>
        <v-spacer />
        <v-btn icon :disabled="requesting" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-expansion-panels v-model="openedPanel" mandatory accordion>
        <v-expansion-panel>
          <v-expansion-panel-header
            disable-icon-rotate
            ripple
            :expand-icon="`mdi-radiobox-${
              bloodPressureGoalsData.timeMode === 'dayNight' ? 'marked' : 'blank'
            }`"
          >
            <span>
              <v-icon left>mdi-theme-light-dark</v-icon>
              日夜
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form v-model="areFormsValid.dayNight" :disabled="requesting">
              <v-subheader>時間範圍</v-subheader>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <vue-slider
                      ref="dayNightTimeRangeSlider"
                      v-model="dayNightTimeRange"
                      tooltip="none"
                      :label-style="{ transform: 'translate(-50%, -60px)' }"
                      :height="24"
                      :min="0"
                      :max="24"
                      :dot-options="{ min: 0, max: 23 }"
                      :clickable="false"
                      :order="false"
                      :marks="dayNightTimeRangeSliderMarks"
                      :rail-style="dayNightTimeRangeSliderRailStyle"
                      :process="dayNightTimeRangeSliderProcess"
                    >
                      <template #dot="{ index }">
                        <v-btn
                          fab
                          x-small
                          dark
                          :color="index === 0 ? 'light-blue' : 'deep-purple'"
                          style="transform: translate(-9px, -9px)"
                        >
                          <v-icon>
                            mdi-{{ index === 0 ? 'white-balance-sunny' : 'moon-waxing-crescent' }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </vue-slider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      label="日間"
                      prepend-icon="mdi-white-balance-sunny"
                      :value="daytimeRangeString"
                      :rules="[inputRules.required]"
                      readonly
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      label="夜間"
                      prepend-icon="mdi-moon-waxing-crescent"
                      :value="nighttimeRangeString"
                      :rules="[inputRules.required]"
                      readonly
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-divider />
              <v-subheader>血壓目標</v-subheader>
              <v-container fluid>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-model.number="
                        bloodPressureGoalsData.daytime.bloodPressureThreshold.systolic
                      "
                      dense
                      label="日間收縮壓"
                      suffix="mmHg"
                      type="number"
                      :rules="[inputRules.required, inputRules.integerRange(20, 300).within]"
                    />
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-model.number="
                        bloodPressureGoalsData.daytime.bloodPressureThreshold.diastolic
                      "
                      dense
                      label="日間舒張壓"
                      suffix="mmHg"
                      type="number"
                      :rules="[inputRules.required, inputRules.integerRange(20, 300).within]"
                    />
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-model.number="
                        bloodPressureGoalsData.nighttime.bloodPressureThreshold.systolic
                      "
                      dense
                      label="夜間收縮壓"
                      suffix="mmHg"
                      type="number"
                      :rules="[inputRules.required, inputRules.integerRange(20, 300).within]"
                    />
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      v-model.number="
                        bloodPressureGoalsData.nighttime.bloodPressureThreshold.diastolic
                      "
                      dense
                      label="夜間舒張壓"
                      suffix="mmHg"
                      type="number"
                      :rules="[inputRules.required, inputRules.integerRange(20, 300).within]"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            disable-icon-rotate
            ripple
            :expand-icon="`mdi-radiobox-${
              bloodPressureGoalsData.timeMode === 'all' ? 'marked' : 'blank'
            }`"
          >
            <span>
              <v-icon left>mdi-calendar-today</v-icon>
              全日
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form v-model="areFormsValid.all" :disabled="requesting">
              <v-subheader>血壓目標</v-subheader>
              <v-container fluid>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model.number="
                        bloodPressureGoalsData.alltime.bloodPressureThreshold.systolic
                      "
                      dense
                      label="收縮壓"
                      suffix="mmHg"
                      type="number"
                      :rules="[inputRules.required, inputRules.integerRange(20, 300).within]"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model.number="
                        bloodPressureGoalsData.alltime.bloodPressureThreshold.diastolic
                      "
                      dense
                      label="舒張壓"
                      suffix="mmHg"
                      type="number"
                      :rules="[inputRules.required, inputRules.integerRange(20, 300).within]"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          depressed
          :loading="requesting"
          :disabled="!areFormsValid[bloodPressureGoalsData.timeMode]"
          @click="done()"
        >
          完成
        </v-btn>
      </v-card-actions>
    </v-card>
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_isShowing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </v-dialog>
</template>

<style lang="scss" scoped>
.v-expansion-panel::before {
  box-shadow: none;
}
</style>

<script>
import Vue from 'vue';
import VueSlider from 'vue-slider-component';
import colors from 'vuetify/lib/util/colors';
import 'vue-slider-component/theme/material.css';

import extensions from '@/mixins/extensions';
import inputRules from '@/mixins/inputRules';
import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
import vDatePicker from '@/mixins/vDatePicker';
import { Date } from '@/extensions';
import { firestore } from '@/firebase';

export default Vue.component(
  'edit-blood-pressure-goals-dialog',
  Vue.extend({
    name: 'EditBloodPressureGoalsDialog',
    components: {
      VueSlider,
    },
    mixins: [inputRules, extensions, messageSnackbar, vDatePicker],
    props: {
      value: { type: Boolean, default: true },
      patientId: { type: String, default: null },
    },
    data: () => ({
      tab: null,
      requesting: false,
      areFormsValid: {
        dayNight: false,
        all: false,
      },
      bloodPressureGoalsData: null,
    }),
    computed: {
      openedPanel: {
        get() {
          return { dayNight: 0, all: 1 }[this.bloodPressureGoalsData.timeMode];
        },
        set(value) {
          this.bloodPressureGoalsData.timeMode = ['dayNight', 'all'][value];
        },
      },
      dayNightTimeRange: {
        get() {
          return [
            this.bloodPressureGoalsData.daytime.beginHour,
            this.bloodPressureGoalsData.nighttime.beginHour,
          ];
        },
        set(value) {
          this.bloodPressureGoalsData.daytime.beginHour = value[0];
          this.bloodPressureGoalsData.nighttime.beginHour = value[1];
        },
      },
      dayNightTimeRangeSliderMarks() {
        const labels = { 4: '4AM', 8: '8AM', 12: '12PM', 16: '4PM', 20: '8PM' };
        return Object.fromEntries(
          Array.from({ length: 24 }).map((_, i) => [
            i,
            {
              label: labels[i],
              style: {
                width: '2px',
                height: '24px',
                backgroundColor: `rgba(255, 255, 255, ${i % 4 === 0 ? 0.3 : 0.15})`,
                transform: 'translateX(12px)',
                borderRadius: '0',
                display: i % 24 === 0 ? 'none' : undefined,
              },
            },
          ]),
        );
      },
      dayNightTimeRangeSliderRailStyle() {
        return {
          backgroundColor: this.getDayNightTimeRangeSliderBackgroundColor(
            this.dayNightTimeRange[0] - this.dayNightTimeRange[1],
          ),
        };
      },
      dayNightTimeRangeSliderProcess() {
        return (value) => [
          [
            value[0],
            value[1],
            {
              backgroundColor: this.getDayNightTimeRangeSliderBackgroundColor(value[1] - value[0]),
            },
          ],
        ];
      },
      daytimeRangeString() {
        return this.getTimeRangeString('daytime', 'nighttime');
      },
      nighttimeRangeString() {
        return this.getTimeRangeString('nighttime', 'daytime');
      },
    },
    created() {
      this.bloodPressureGoalsData = _.cloneDeep(this.$store.state.patient.bloodPressureGoals);
    },
    methods: {
      getDayNightTimeRangeSliderBackgroundColor(value) {
        const key = this.$vuetify.theme.dark ? 'darken1' : 'lighten1';
        if (value > 0) return colors.lightBlue[key];
        else if (value < 0) return colors.deepPurple[key];
        return colors.grey[key];
      },
      getTimeRangeString(fromTime, toTime) {
        const localized = Date.localizedHourStrings;
        const from = this.bloodPressureGoalsData[fromTime].beginHour;
        const to = this.bloodPressureGoalsData[toTime].beginHour;
        if (from < to) return `${localized[from]}～${localized[to]}`;
        else if (from > to) return `${localized[from]}～隔天${localized[to]}`;
        return null;
      },
      async done() {
        this.requesting = true;
        await firestore
          .collection('patients')
          .doc(this.$store.state.patientId)
          .update({
            bloodPressureGoals: this.bloodPressureGoalsData,
          })
          .then(() => this.close())
          .catch((error) =>
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定'),
          );
        this.requesting = false;
      },
      close() {
        this.$_mixin_messageSnackbar_hide();
        this.$emit('input', false);
      },
    },
  }),
);
</script>

<template>
  <v-dialog
    class="measurement-add-or-edit-schedule-dialog"
    :value="value"
    persistent
    max-width="400px"
    @input="$emit('input', $event)"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <v-card>
      <v-toolbar color="primary" dark dense>
        <template v-if="_.isString(id)">
          <v-icon left>mdi-timeline</v-icon>
          <v-toolbar-title>編輯量測排程</v-toolbar-title>
        </template>
        <template v-else>
          <v-icon left>mdi-timeline-plus</v-icon>
          <v-toolbar-title>新增量測排程</v-toolbar-title>
        </template>
        <v-spacer />
        <v-btn icon :disabled="requesting" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-form v-model="isFormValid">
          <v-container>
            <v-row>
              <v-col>
                <v-dialog
                  ref="pickBeginAtDialog"
                  v-model="pickBeginAtDialogIsOpen"
                  width="290px"
                  persistent
                  :return-value.sync="beginAtString"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="localizedBeginAtString"
                      dense
                      label="開始於"
                      readonly
                      v-bind="attrs"
                      :rules="[inputRules.required]"
                      v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-date-time-picker
                      :key="beginAtPickerKey"
                      v-model="beginAtString"
                      color="primary"
                      dark
                      full-width
                      scrollable
                    />
                    <v-divider />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="error" text @click="pickBeginAtDialogIsOpen = false">
                        取消
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        :disabled="!beginAtString"
                        @click="$refs.pickBeginAtDialog.save(beginAtString)"
                      >
                        確定
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model.number="measurementScheduleData.eachMeasurement.times"
                  dense
                  label="每回的量測次數"
                  suffix="次"
                  type="number"
                  :rules="[inputRules.required, inputRules.integerRange(1, 255).within]"
                />
              </v-col>
              <v-col v-if="measurementScheduleData.eachMeasurement.times !== 1" cols="6">
                <v-text-field
                  v-model.number="measurementScheduleData.eachMeasurement.intervalInMinutes"
                  dense
                  label="每回的量測間隔"
                  suffix="分鐘"
                  type="number"
                  :rules="[inputRules.required, inputRules.integerRange(0, 255).within]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn text color="error" :disabled="requesting" @click="close()">取消</v-btn>
        <v-btn
          depressed
          color="primary"
          :loading="requesting"
          :disabled="!isFormValid"
          @click="done()"
        >
          完成
        </v-btn>
      </v-card-actions>
    </v-card>
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_isShowing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </v-dialog>
</template>

<script>
import Vue from 'vue';

import * as dateTimeString from '@/utils/dateTimeString';
import VDateTimePicker from '@/components/Vuetify/VDateTimePicker';
import extensions from '@/mixins/extensions';
import inputRules from '@/mixins/inputRules';
import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
import { Date } from '@/extensions';
import { firestore } from '@/firebase';
import { calculateMeasurementScheduleId } from '@/models/Measure';

export default Vue.component(
  'measurement-add-or-edit-schedule-dialog',
  Vue.extend({
    name: 'MeasurementAddOrEditScheduleDialog',
    components: { VDateTimePicker },
    mixins: [inputRules, extensions, messageSnackbar],
    props: {
      value: { type: Boolean, default: true },
      id: { type: String, default: null },
    },
    data: () => ({
      requesting: false,
      isFormValid: false,
      pickBeginAtDialogIsOpen: false,
      beginAtPickerKey: 0,
      measurementScheduleData: null,
      measurementScheduleDataTemplate: {
        beginAt: null,
        eachMeasurement: {
          times: null,
          intervalInMinutes: null,
        },
        status: 'pending',
        measurementRecords: new Object(),
      },
    }),
    computed: {
      beginAtString: {
        get() {
          if (!(this.measurementScheduleData.beginAt instanceof Date)) return null;
          return dateTimeString.fromDate(this.measurementScheduleData.beginAt);
        },
        set(value) {
          this.measurementScheduleData.beginAt = _.isString(value)
            ? dateTimeString.toDate(value)
            : null;
        },
      },
      localizedBeginAtString() {
        if (!(this.measurementScheduleData.beginAt instanceof Date)) return null;
        return this.measurementScheduleData.beginAt.longLocalizedString;
      },
    },
    watch: {
      pickBeginAtDialogIsOpen(value) {
        if (!value) this.beginAtPickerKey += 1;
      },
    },
    created() {
      this.measurementScheduleData = _.cloneDeep(
        _.isString(this.id)
          ? this.$store.state.measurementSchedules.filter((v) => v.id === this.id)[0]
          : this.measurementScheduleDataTemplate,
      );
      if (
        _.isObject(this.measurementScheduleData.beginAt) &&
        !(this.measurementScheduleData.beginAt instanceof Date)
      ) {
        this.measurementScheduleData.beginAt = Date.withoutSeconds(
          this.measurementScheduleData.beginAt.toDate(),
        );
      }
    },
    methods: {
      async done() {
        this.requesting = true;
        const measurementSchedules = firestore
          .collection('patients')
          .doc(this.$store.state.patientId)
          .collection('measurementSchedules');
        const batch = firestore.batch();
        const id = calculateMeasurementScheduleId(this.measurementScheduleData);
        batch.set(measurementSchedules.doc(id), this.measurementScheduleData);
        if (_.isString(this.id) && id !== this.id) batch.delete(measurementSchedules.doc(this.id));
        await batch
          .commit()
          .then(() => this.close())
          .catch((error) =>
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定'),
          );
        this.requesting = false;
      },
      close() {
        this.$emit('input', false);
      },
    },
  }),
);
</script>

<template>
  <div class="v-date-time-picker">
    <v-toolbar dense :color="color" :dark="dark">
      <v-icon left>mdi-calendar-clock</v-icon>
      <v-spacer />
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <template #extension>
        <v-tabs v-model="tab" fixed-tabs :background-color="color">
          <v-tab href="#date">
            <v-icon>mdi-calendar</v-icon>
          </v-tab>
          <v-tab href="#time">
            <v-icon>mdi-clock-outline</v-icon>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item value="date">
        <v-date-picker
          v-model="dateString"
          class="v-date-time-picker"
          flat
          header-color="base"
          show-week
          :color="color"
          :full-width="fullWidth"
          :scrollable="scrollable"
          :day-format="$_mixin_vDatePicker_dayFormat"
          @change="tab = 'time'"
        />
      </v-tab-item>
      <v-tab-item value="time">
        <v-time-picker
          v-model="timeString"
          ampm-in-title
          class="v-date-time-picker"
          flat
          format="ampm"
          header-color="base"
          :color="color"
          :full-width="fullWidth"
          :scrollable="scrollable"
          :use-seconds="useSeconds"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style lang="scss">
.v-date-time-picker {
  border-radius: 0px;
  .theme--light {
    .v-date-picker-title,
    .v-time-picker-title {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .v-date-picker-title__year {
    font-size: 22px;
  }
  .v-date-picker-title__date {
    font-size: 40px;
  }
}
</style>

<script>
import Vue from 'vue';

import * as dateTimeString from '@/utils/dateTimeString';
import vDatePicker from '@/mixins/vDatePicker';

export default Vue.component(
  'v-date-time-picker',
  Vue.extend({
    name: 'VDateTimePicker',
    mixins: [vDatePicker],
    props: {
      value: { type: String, default: null },
      color: { type: String, default: null },
      dark: { type: Boolean, default: false },
      fullWidth: { type: Boolean, default: false },
      scrollable: { type: Boolean, default: false },
      useSeconds: { type: Boolean, default: false },
    },
    data: () => ({
      tab: null,
      dateString: null,
      timeString: null,
    }),
    computed: {
      title() {
        return this.value
          ? dateTimeString.toDate(this.value).toLocaleString(undefined, {
              dateStyle: 'short',
              timeStyle: this.useSeconds ? 'long' : 'short',
            })
          : '沒有選擇日期與時間';
      },
    },
    watch: {
      value(value) {
        this.setDateTimeString(value);
      },
      dateString(value) {
        if (value && this.timeString) this.$emit('input', `${value} ${this.timeString}`);
      },
      timeString(value) {
        if (this.dateString && value) this.$emit('input', `${this.dateString} ${value}`);
      },
    },
    created() {
      this.setDateTimeString(this.value);
    },
    methods: {
      setDateTimeString(value) {
        if (_.isString(value)) {
          this.dateString = value.substring(0, 10);
          this.timeString = value.substring(11, 19);
        } else {
          this.tab = 'date';
          this.dateString = null;
          this.timeString = null;
        }
      },
    },
  }),
);
</script>

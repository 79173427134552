import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Root',
    redirect: { name: 'Report' },
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import(/* webpackChunkName: "report" */ '@/views/Report'),
  },
  {
    path: '/records',
    name: 'Records',
    component: () => import(/* webpackChunkName: "records" */ '@/views/Records'),
  },
  {
    path: '/trends',
    name: 'Trends',
    component: () => import(/* webpackChunkName: "trends" */ '@/views/Trends'),
  },
  {
    path: '*',
    redirect: { name: 'Root' },
  },
];

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

import Chart from 'chart.js';
import Vue from 'vue';
import VueChart from 'vue-chartjs';
import { paramCase } from 'change-case';

Chart.defaults.global.animation.easing = 'easeInOutExpo';
Chart.defaults.global.animation.duration = 1200;

const ChartJs = {
  install(Vue) {
    for (const chartType in VueChart) {
      if (
        typeof VueChart[chartType] === 'object' &&
        typeof VueChart[chartType].methods === 'object'
      ) {
        Vue.component(
          `${paramCase(chartType)}-chart`,
          Vue.extend({
            name: `${chartType}Chart`,
            extends: VueChart[chartType],
            mixins: [VueChart.mixins.reactiveProp],
            props: {
              value: {
                type: Object,
                default: null,
              },
              options: {
                type: Object,
                default: null,
              },
            },
            mounted() {
              this.addPlugin({
                // Reference: https://stackoverflow.com/a/45652892
                id: 'showValueAroundPoint',
                afterDatasetsDraw(chart) {
                  const ctx = chart.ctx; // Type: CanvasRenderingContext2D
                  chart.data.datasets.forEach((dataset, i) => {
                    const datasetMeta = chart.getDatasetMeta(i);
                    if (datasetMeta.hidden) return;
                    datasetMeta.data.forEach((point, j) => {
                      const value = dataset.data[j];
                      const cp = point.getCenterPoint();
                      const r = point._model.radius || point._model.base - cp.y;
                      ctx.save();
                      // Default configurations in https://bit.ly/38BTFpg
                      const fontSize = 12;
                      const lineHeight = 1.2;
                      ctx.font = `normal ${fontSize}px / ${lineHeight} sans-serif`;
                      ctx.textAlign = 'center';
                      ctx.textBaseline = 'middle';
                      ctx.fillStyle = '#666';
                      // TODO: deal with out of bounds text
                      ctx.fillText(`${value}`, cp.x, cp.y - r - fontSize * lineHeight);
                      ctx.restore();
                    });
                  });
                },
              });
              this.renderChart(this.chartData, this.options);
              this.$emit('input', this.$data._chart);
            },
          }),
        );
      }
    }
  },
};

Vue.use(ChartJs);

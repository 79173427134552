<template>
  <v-dialog
    class="measurement-add-or-edit-plan-set-dialog"
    :value="value"
    persistent
    max-width="400px"
    @input="$emit('input', $event)"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <v-card>
      <v-toolbar color="primary" dark dense>
        <template v-if="_.isPlainObject(set)">
          <v-icon left>mdi-pencil</v-icon>
          <v-toolbar-title>編輯量測計畫組</v-toolbar-title>
        </template>
        <template v-else>
          <v-icon left>mdi-plus</v-icon>
          <v-toolbar-title>新增量測計畫組</v-toolbar-title>
        </template>
        <v-spacer />
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-form v-model="isFormValid">
          <v-container>
            <template v-if="type === 'period'">
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="measurementPlanSetData.fromHour"
                    dense
                    prefix="從"
                    :items="periodFromHourItems"
                    :rules="[inputRules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="measurementPlanSetData.toHour"
                    dense
                    prefix="到"
                    :items="periodToHourItems"
                    :rules="[inputRules.required]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.number="measurementPlanSetData.intervalInMinutes"
                    dense
                    label="間隔"
                    suffix="分鐘"
                    type="number"
                    :rules="[inputRules.required, inputRules.integerRange(1, 1439).within]"
                  />
                </v-col>
              </v-row>
            </template>
            <v-row v-else-if="type === 'fixed'">
              <v-col>
                <v-menu
                  ref="pickElapsedTimeInMinutesMenu"
                  v-model="pickElapsedTimeInMinutesMenuIsOpen"
                  :close-on-content-click="false"
                  :return-value.sync="elapsedTimeInMinutesString"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="localizedElapsedTimeInMinutesString"
                      dense
                      label="開始於"
                      readonly
                      v-bind="attrs"
                      :rules="[inputRules.required]"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    :key="elapsedTimeInMinutesPickerKey"
                    v-model="elapsedTimeInMinutesString"
                    ampm-in-title
                    scrollable
                  >
                    <v-spacer />
                    <v-btn text color="error" @click="pickElapsedTimeInMinutesMenuIsOpen = false">
                      取消
                    </v-btn>
                    <v-btn
                      depressed
                      color="primary"
                      :disabled="!elapsedTimeInMinutesString"
                      @click="$refs.pickElapsedTimeInMinutesMenu.save(elapsedTimeInMinutesString)"
                    >
                      確定
                    </v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" :disabled="!isFormValid" @click="done()">完成</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';

import extensions from '@/mixins/extensions';
import inputRules from '@/mixins/inputRules';
import { Date } from '@/extensions';

export default Vue.component(
  'measurement-add-or-edit-plan-set-dialog',
  Vue.extend({
    name: 'MeasurementAddOrEditPlanSetDialog',
    mixins: [inputRules, extensions],
    props: {
      value: { type: Boolean, default: true },
      type: { type: String, default: null },
      set: { type: Object, default: null },
    },
    data: () => ({
      isFormValid: false,
      pickElapsedTimeInMinutesMenuIsOpen: false,
      elapsedTimeInMinutesPickerKey: 0,
      measurementPlanSetData: null,
      measurementPlanSetDataTemplate: {
        period: {
          fromHour: null,
          toHour: null,
          intervalInMinutes: null,
          eachMeasurement: null,
        },
        fixed: {
          elapsedTimeInMinutes: null,
          eachMeasurement: null,
        },
      },
    }),
    computed: {
      elapsedTimeInMinutesString: {
        get() {
          if (!_.isNumber(this.measurementPlanSetData.elapsedTimeInMinutes)) return null;
          const hour = Math.trunc(this.measurementPlanSetData.elapsedTimeInMinutes / 60);
          const minute = this.measurementPlanSetData.elapsedTimeInMinutes % 60;
          return `${hour}`.padStart(2, '0') + ':' + `${minute}`.padStart(2, '0');
        },
        set(value) {
          if (_.isString(value)) {
            const [hourString, minuteString] = value.split(':');
            this.measurementPlanSetData.elapsedTimeInMinutes =
              parseInt(hourString) * 60 + parseInt(minuteString);
          } else {
            this.measurementPlanSetData.elapsedTimeInMinutes = null;
          }
        },
      },
      localizedElapsedTimeInMinutesString() {
        if (!_.isNumber(this.measurementPlanSetData.elapsedTimeInMinutes)) return null;
        return Date.localizedHourMinuteStrings[this.measurementPlanSetData.elapsedTimeInMinutes];
      },
      periodFromHourItems() {
        return Date.localizedHourStrings.map((v, i) => ({ text: v, value: i }));
      },
      periodToHourItems() {
        return Date.localizedHourStrings.map((v, i) => ({
          text: ((this.measurementPlanSetData?.fromHour ?? 0) >= i ? '隔天' : '') + v,
          value: i,
        }));
      },
    },
    watch: {
      pickElapsedTimeInMinutesMenuIsOpen(value) {
        if (!value) this.elapsedTimeInMinutesPickerKey += 1;
      },
    },
    created() {
      this.measurementPlanSetData = _.cloneDeep(
        _.isPlainObject(this.set) ? this.set : this.measurementPlanSetDataTemplate[this.type],
      );
    },
    methods: {
      done() {
        this.$emit('set', this.measurementPlanSetData);
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
    },
  }),
);
</script>

import 'firebase/auth';
import 'firebase/firestore';
import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyAgpKAMdHHGYaYVmOzPwJHqnrQcQhPSxFU',
  authDomain: 'smart-bpm-ndhu.firebaseapp.com',
  databaseURL: 'https://smart-bpm-ndhu.firebaseio.com',
  projectId: 'smart-bpm-ndhu',
  storageBucket: 'smart-bpm-ndhu.appspot.com',
  messagingSenderId: '639301123434',
  appId: '1:639301123434:web:43b5a1ca424695ca963913',
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();
export const firestore = firebase.firestore();

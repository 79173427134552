export default class ExtendedDate extends Date {
  static withoutSeconds() {
    const date = new this(...arguments);
    date.setSeconds(0, 0);
    return date;
  }
  static onlyDate() {
    const date = new this(...arguments);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  static get localizedHourStrings() {
    return Array.from({ length: 24 }, (_, i) =>
      this.withoutSeconds(1, 0, 1, i).toLocaleString(undefined, {
        hour: '2-digit',
      }),
    );
  }

  static get localizedHourMinuteStrings() {
    return Array.from(
      { length: 1440 },
      (_, i) => this.withoutSeconds(1, 0, 1, Math.trunc(i / 60), i % 60).longLocalizedTimeString,
    );
  }

  toISOString() {
    const format2Digits = (v) => `${Math.abs(v)}`.padStart(2, '0');
    const date = new Date(this);
    const timezoneOffset = date.getTimezoneOffset();
    const offsetHours = Math.trunc(timezoneOffset / 60);
    const offsetMinutes = timezoneOffset % 60;
    date.setHours(date.getHours() - offsetHours, date.getMinutes() - offsetMinutes);
    return (
      date.toISOString().replace('Z', timezoneOffset > 0 ? '-' : '+') +
      `${format2Digits(offsetHours)}:${format2Digits(offsetMinutes)}`
    );
  }

  // FIXME: Safari issue, change `toLocaleString` to https://date-fns.org/

  get longLocalizedString() {
    return this.toLocaleString(undefined, {
      dateStyle: 'full',
      timeStyle: 'short',
    });
  }

  get shortLocalizedString() {
    return this.toLocaleString(undefined, {
      dateStyle: 'short',
      timeStyle: 'short',
    });
  }

  get rocDateString() {
    const rocYear = parseInt(this.getFullYear()) - 1911;
    const formatedRocYearString =
      '民國' + (rocYear > 0 ? `${rocYear}` : `前${(rocYear - 1) * -1}`) + '年';
    return `${formatedRocYearString}${this.getMonth() + 1}月${this.getDate()}日`;
  }

  get longLocalizedDateString() {
    return this.toLocaleString(undefined, {
      dateStyle: 'full',
    });
  }

  get shortLocalizedDateString() {
    return this.toLocaleString(undefined, {
      dateStyle: 'short',
    });
  }

  get longLocalizedTimeString() {
    return this.toLocaleString(undefined, {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  get shortLocalizedTimeString() {
    return this.toLocaleString(undefined, {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    });
  }
}

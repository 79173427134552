import * as math from 'mathjs';
import Vue from 'vue';

function isValueEmpty(value) {
  return _.isString(value) ? value.trim().length === 0 : _.isNil(value);
}

export default Vue.extend({
  data: () => ({
    inputRules: {
      required(value) {
        return isValueEmpty(value) ? '必須的' : true;
      },
      email(value) {
        if (isValueEmpty(value)) return true;
        // Reference: https://bit.ly/2OaHDLK
        const pattern =
          '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\' +
          's@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,' +
          '3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
        if (!new RegExp(pattern).test(value.trim())) return '無效的電子信箱格式';
        return true;
      },
      password(value) {
        if (_.isString(value)) {
          if (!/^[\x20-\x7e]+$/.test(value)) return '只能使用0～9、A～Z、a～z和常見的標點符號';
          else if (value.length < 6) return '必須使用至少6個字元作為你的密碼';
        }
        return true;
      },
      url(value) {
        if (isValueEmpty(value)) return true;
        // Reference: https://bit.ly/3svZivX
        const pattern =
          '^(?:(?:https?|ftp):\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
          '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\' +
          '.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d' +
          '|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\u' +
          'ffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u0' +
          '0a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))(?::\\d{2,5})?(?:[/?#]\\S*)?$';
        if (!new RegExp(pattern).test(value.trim())) return '無效的網址格式';
        return true;
      },
      idNumber(value) {
        if (isValueEmpty(value)) return true;
        const alphabetToNs = (alphabet) =>
          ((ascii) =>
            ascii >= 'A'.charCodeAt() && ascii < 'I'.charCodeAt()
              ? ascii - 55
              : ascii >= 'J'.charCodeAt() && ascii < 'O'.charCodeAt()
              ? ascii - 56
              : ascii >= 'P'.charCodeAt() && ascii < 'W'.charCodeAt()
              ? ascii - 57
              : ascii >= 'X'.charCodeAt() && ascii < 'Z'.charCodeAt()
              ? ascii - 58
              : { I: 34, O: 35, W: 32, Z: 33 }[String.fromCharCode(ascii)])(
            alphabet.charCodeAt(),
          ).toString();
        const na = ((value) =>
          ((value) =>
            /^[A-Z][1289]\d{8}$/.test(value)
              ? alphabetToNs(value[0]) + value.substring(1)
              : /^[A-Z][A-D]\d{8}$/.test(value)
              ? alphabetToNs(value[0]) + alphabetToNs(value[1])[1] + value.substring(2)
              : null)(value)
            ?.split('')
            .map((c) => parseInt(c)))(value);
        if (_.isArray(na)) {
          const ka = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];
          if (math.sum(na.map((n, i) => n * ka[i])) % 10 === 0) return true;
        }
        return '無效的身分證或居留證號';
      },
      integerRange(min, max) {
        return {
          within(value) {
            if (isValueEmpty(value)) return true;
            return _.isNumber(value) && math.isInteger(value) && value >= min && value <= max
              ? true
              : `值必須介於${min}和${max}之間`;
          },
        };
      },
      filename(value) {
        if (isValueEmpty(value)) return true;
        // Reference: https://stackoverflow.com/a/53635003
        if (/^(con|prn|aux|nul|((com|lpt)[0-9]))$|(["*/:<>?\\|])|(\.|\s)$/im.test(value.trim()))
          return '無效的檔案名稱';
        return true;
      },
    },
  }),
});

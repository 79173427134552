import * as math from 'mathjs';
import blakejs from 'blakejs';
import regression from 'regression';

import { Date } from '@/extensions';

export default class Measure {
  constructor() {
    this._resetResults();
  }

  _resetResults() {
    this.results = {
      statistics: {
        daytimeBeginHour: null,
        nighttimeBeginHour: null,
        measureCount: null,
        measureSuccessRate: null,
      },
      means: {
        allTimes: null,
        daytime: null,
        nighttime: null,
      },
      bloodPressureNighttimeDropRates: {
        systolic: null,
        diastolic: null,
        areRisedBeginEachDaytime: [],
      },
      hypertensionLoads: {
        daytime: {
          rate: null,
          systolicThreshold: null,
          diastolicThreshold: null,
        },
        nighttime: {
          rate: null,
          systolicThreshold: null,
          diastolicThreshold: null,
        },
      },
      bloodPressureVariances: {
        sd: null,
        cv: null,
        arv: null,
        aasi: null,
      },
      recordsTable: [],
    };
  }

  _isDaytime(date) {
    const daytimeBeginHour = this.bloodPressureGoals.daytime.beginHour;
    const nighttimeBeginHour = this.bloodPressureGoals.nighttime.beginHour;
    const hour = date.getHours();
    return daytimeBeginHour < nighttimeBeginHour
      ? hour >= daytimeBeginHour && hour < nighttimeBeginHour
      : hour + 24 >= daytimeBeginHour && hour + 24 < nighttimeBeginHour + 24;
  }

  _meanArterialBloodPressure(systolic, diastolic) {
    return (systolic * 1 + diastolic * 2) / 3;
  }

  _preprocessRecords() {
    const records = this.records
      .slice()
      .filter((r) => _.isString(r.measuredData.status))
      .sort((a, b) => a.measuredAt.toDate().getTime() - b.measuredAt.toDate().getTime());
    this._records = new Object();
    this._records.sorted = records;
    this._records.count = records.length;
    this._records.dates = records.map((r) => r.measuredAt.toDate());
    this._records.areDaytimes = this._records.dates.map((d) => this._isDaytime(d));
    for (const [key, value] of Object.entries({
      systolicBloodPressures: (r) => r.measuredData.bloodPressure.systolic,
      diastolicBloodPressures: (r) => r.measuredData.bloodPressure.diastolic,
      meanArterialBloodPressures: (r) =>
        this._meanArterialBloodPressure(
          r.measuredData.bloodPressure.systolic,
          r.measuredData.bloodPressure.diastolic,
        ),
      heartRates: (r) => r.measuredData.heartRate,
    })) {
      const record = new Object();
      record.allTimes = records.map(value);
      record.daytime = record.allTimes.filter((_, i) => this._records.areDaytimes[i]);
      record.nighttime = record.allTimes.filter((_, i) => !this._records.areDaytimes[i]);
      this._records[key] = record;
    }
  }

  _setStatistics() {
    const statistics = this.results.statistics;
    statistics.daytimeBeginHour = this.bloodPressureGoals.daytime.beginHour;
    statistics.nighttimeBeginHour = this.bloodPressureGoals.nighttime.beginHour;
    statistics.measureCount = this.records.length;
    statistics.measureSuccessRate = this._records.count / this.records.length;
  }

  _setMeans() {
    const means = this.results.means;
    for (const time of ['allTimes', 'daytime', 'nighttime']) {
      if (this._records.meanArterialBloodPressures[time].length > 0) {
        means[time] = new Object();
        for (const key of ['systolicBloodPressure', 'diastolicBloodPressure', 'heartRate'])
          means[time][key] = math.mean(this._records[`${key}s`][time]);
      }
    }
  }

  _setbloodPressureNighttimeDropRates() {
    const bloodPressureNighttimeDropRates = this.results.bloodPressureNighttimeDropRates;
    const means = this.results.means;
    if (means.daytime !== null && means.nighttime !== null) {
      for (const key of ['systolic', 'diastolic']) {
        bloodPressureNighttimeDropRates[key] =
          (means.daytime[`${key}BloodPressure`] - means.nighttime[`${key}BloodPressure`]) /
          means.daytime[`${key}BloodPressure`];
      }
    }
    // FIXME: new algr fix range
    const recordRanges = ((records) => {
      const recordRanges = new Array();
      let last = 0;
      for (let i = 1; i <= records.count; i++) {
        if (
          i < records.count &&
          records.dates[i].toDateString() === records.dates[last].toDateString() &&
          records.areDaytimes[i] === records.areDaytimes[last]
        )
          continue;
        recordRanges.push({
          isDaytime: records.areDaytimes[last],
          range: [last, i],
        });
        last = i;
      }
      return recordRanges;
    })(this._records);
    for (let i = 1; i < recordRanges.length; i++) {
      if (recordRanges[i - 1].isDaytime || !recordRanges[i].isDaytime) continue;
      const meanOfNighttimeMeanArterialBloodPressures = math.mean(
        this._records.meanArterialBloodPressures.allTimes.slice(...recordRanges[i - 1].range),
      );
      const daytimeMeanArterialBloodPressuresIn2Hours = (() =>
        this._records.meanArterialBloodPressures.allTimes.filter((_, j) => {
          if (j < recordRanges[i].range[0] || j >= recordRanges[i].range[1]) return false;
          const hour = this._records.dates[j].getHours();
          const beginHour = this.bloodPressureGoals.daytime.beginHour;
          if (hour >= beginHour && hour - beginHour <= 2) return true;
          else if (hour < beginHour && hour + 24 - beginHour <= 2) return true;
          return false;
        }))();
      if (daytimeMeanArterialBloodPressuresIn2Hours.length > 0) {
        const maxOfDaytimeMeanArterialBloodPressuresIn2Hours = math.max(
          daytimeMeanArterialBloodPressuresIn2Hours,
        );
        bloodPressureNighttimeDropRates.areRisedBeginEachDaytime.push(
          maxOfDaytimeMeanArterialBloodPressuresIn2Hours >
            meanOfNighttimeMeanArterialBloodPressures,
        );
      }
    }
  }

  _setHypertensionLoads() {
    const hypertensionLoads = this.results.hypertensionLoads;
    for (const time of ['daytime', 'nighttime']) {
      const hypertensionLoad = hypertensionLoads[time];
      hypertensionLoad.rate = 0;
      for (const key of ['systolic', 'diastolic']) {
        hypertensionLoad[`${key}Threshold`] = this.bloodPressureGoals[time].bloodPressureThreshold[
          key
        ];
        hypertensionLoad.rate += this._records[`${key}BloodPressures`][time].filter(
          (v) => v > hypertensionLoad[`${key}Threshold`],
        ).length;
      }
      if (this._records.meanArterialBloodPressures[time].length === 0) hypertensionLoad.rate = null;
      else hypertensionLoad.rate /= this._records.meanArterialBloodPressures[time].length * 2;
    }
  }

  _setBloodPressureVariances() {
    const bloodPressureVariances = this.results.bloodPressureVariances;
    const xs = this._records.meanArterialBloodPressures.allTimes;
    const mean = math.mean(xs);
    bloodPressureVariances.sd = math.std(xs, 'unbiased');
    bloodPressureVariances.cv = bloodPressureVariances.sd / mean;
    bloodPressureVariances.arv =
      math.sum(Array.from({ length: xs.length - 1 }).map((_, i) => math.abs(xs[i + 1] - xs[i]))) /
      (xs.length - 1);
    bloodPressureVariances.aasi =
      1 -
      regression.linear(
        Array.from({ length: xs.length }, (_, i) => [
          this._records.systolicBloodPressures.allTimes[i],
          this._records.diastolicBloodPressures.allTimes[i],
        ]),
      ).equation[0];
  }

  _setRecordsTable() {
    const isBloodPressureOverThreshold = (date, bloodPressure, type) => {
      const time = this._isDaytime(date) ? 'daytime' : 'nighttime';
      return bloodPressure > this.bloodPressureGoals[time].bloodPressureThreshold[type];
    };
    const recordsTable = this.results.recordsTable;
    let lastDateString = '';
    for (const record of this._records.sorted) {
      const date = Date.withoutSeconds(record.measuredAt.toDate());
      const dateString = date.toDateString();
      if (lastDateString !== dateString) {
        lastDateString = dateString;
        recordsTable.push({
          type: 'date',
          values: [date.longLocalizedDateString],
        });
      }
      const localeTimeString = date.longLocalizedTimeString;
      const localeTimeStringWithoutMidday = ((date) => {
        const dateWithoutMidday = Date.withoutSeconds(date);
        const hour = dateWithoutMidday.getHours();
        if (hour > 12) dateWithoutMidday.setHours(hour - 12);
        else if (hour === 0) dateWithoutMidday.setHours(hour + 12);
        return dateWithoutMidday.shortLocalizedTimeString;
      })(date);
      const localeMiddayString = ((localeTimeString, localeTimeStringWithoutMidday) => {
        let newLocaleTimeStringWithoutMidday = localeTimeStringWithoutMidday;
        if (!localeTimeString.includes(localeTimeStringWithoutMidday)) {
          // Fix Firefox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1284868
          if (newLocaleTimeStringWithoutMidday[0] === '0')
            newLocaleTimeStringWithoutMidday = localeTimeStringWithoutMidday.slice(1);
        }
        return localeTimeString.replace(newLocaleTimeStringWithoutMidday, '').trim();
      })(localeTimeString, localeTimeStringWithoutMidday);
      // TODO: update handle error
      if (_.isString(record.measuredData.status)) {
        const row = {
          type: 'success',
          values: [
            localeMiddayString,
            localeTimeStringWithoutMidday,
            {},
            {},
            record.measuredData.heartRate,
          ],
        };
        for (const [i, type] of ['systolic', 'diastolic'].entries()) {
          row.values[i + 2].value = record.measuredData.bloodPressure[type];
          row.values[i + 2].isOver = isBloodPressureOverThreshold(
            date,
            row.values[i + 2].value,
            type,
          );
        }
        recordsTable.push(row);
      } else {
        recordsTable.push({
          type: 'error',
          values: [localeMiddayString, localeTimeStringWithoutMidday, record.measuredData.status],
        });
      }
    }
    let localeMiddayString = '';
    for (const row of recordsTable) {
      if (row.type === 'date') {
        localeMiddayString = '';
      } else {
        if (row.values[0] === localeMiddayString) row.values[0] = '';
        else localeMiddayString = row.values[0];
      }
    }
  }

  clear() {
    this._resetResults();
  }

  update(bloodPressureGoals, records) {
    this._resetResults();
    this.bloodPressureGoals = bloodPressureGoals;
    this.records = records;
    this._preprocessRecords();
    this._setStatistics();
    this._setMeans();
    this._setbloodPressureNighttimeDropRates();
    this._setHypertensionLoads();
    this._setBloodPressureVariances();
    this._setRecordsTable();
  }
}

export function calculateMaxMeasurementTimes(measurementPlan) {
  let maxMeasurementTimes = 0;
  for (const set of measurementPlan.plan.sets) {
    const multiple = (() => {
      if (measurementPlan.type === 'period') {
        const fromHour = set.fromHour;
        const toHour = set.fromHour < set.toHour ? set.toHour : 24 + set.toHour;
        return Math.floor(((toHour - fromHour) * 60) / set.intervalInMinutes);
      } else if (measurementPlan.type === 'fixed') {
        return 1;
      }
    })();
    maxMeasurementTimes +=
      (set.eachMeasurement?.times ?? measurementPlan.plan.eachMeasurement.times) * multiple;
  }
  maxMeasurementTimes *= measurementPlan.plan.days;
  return maxMeasurementTimes;
}

export function calculateMeasurementScheduleId(measurementScheduleData) {
  const buffer = new ArrayBuffer(10);
  const view = new DataView(buffer);
  view.setBigInt64(0, BigInt(measurementScheduleData.beginAt.getTime()));
  view.setUint8(8, measurementScheduleData.eachMeasurement.times);
  if (measurementScheduleData.eachMeasurement.times > 1)
    view.setUint8(9, measurementScheduleData.eachMeasurement.intervalInMinutes);
  return blakejs.blake2bHex(new Uint8Array(buffer), null, 16);
}

<template>
  <v-dialog
    class="add-or-edit-patient-dialog"
    :value="value"
    persistent
    max-width="320px"
    @input="$emit('input', $event)"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <v-card>
      <v-toolbar color="primary" dark dense>
        <template v-if="_.isString(patientId)">
          <v-icon left>mdi-account-edit</v-icon>
          <v-toolbar-title>編輯病人—{{ $store.state.patient.profile.fullName }}</v-toolbar-title>
        </template>
        <template v-else>
          <v-icon left>mdi-account-plus</v-icon>
          <v-toolbar-title>
            新增病人{{
              patientData.profile.fullName && patientData.profile.fullName.trim()
                ? `—${patientData.profile.fullName.trim()}`
                : ''
            }}
          </v-toolbar-title>
        </template>
        <v-spacer />
        <v-btn icon :disabled="requesting" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <v-form v-model="isFormValid" :disabled="requesting">
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  id="name"
                  v-model="patientData.profile.fullName"
                  clearable
                  label="全名"
                  name="name"
                  dense
                  :rules="[inputRules.required]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="patientData.profile.idNumber"
                  clearable
                  label="身分證／居留證號"
                  dense
                  :rules="[inputRules.required, inputRules.idNumber]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-dialog
                  ref="pickBirthDateDialog"
                  v-model="pickBirthDateDialogIsOpen"
                  width="290px"
                  persistent
                  :return-value.sync="patientData.profile.birthDate"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="birthDateString"
                      dense
                      label="生日"
                      readonly
                      :suffix="ageString"
                      v-bind="attrs"
                      :rules="[inputRules.required]"
                      v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-date-picker
                      :key="birthDatePickerKey"
                      v-model="patientData.profile.birthDate"
                      full-width
                      scrollable
                      :day-format="$_mixin_vDatePicker_dayFormat"
                      :year-format="$_mixin_vDatePicker_rocYearFormat"
                      :header-date-format="$_mixin_vDatePicker_rocHeaderDateFormat"
                    />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="error" text @click="pickBirthDateDialogIsOpen = false">
                        取消
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        :disabled="!patientData.profile.birthDate"
                        @click="$refs.pickBirthDateDialog.save(patientData.profile.birthDate)"
                      >
                        確定
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          depressed
          :loading="requesting"
          :disabled="!isFormValid"
          @click="done()"
        >
          完成
        </v-btn>
      </v-card-actions>
    </v-card>
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_isShowing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import 'vue-slider-component/theme/material.css';

import * as dateTimeString from '@/utils/dateTimeString';
import extensions from '@/mixins/extensions';
import inputRules from '@/mixins/inputRules';
import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
import vDatePicker from '@/mixins/vDatePicker';
import { Date } from '@/extensions';
import { firestore } from '@/firebase';

export default Vue.component(
  'add-or-edit-patient-dialog',
  Vue.extend({
    name: 'AddOrEditPatientDialog',
    mixins: [inputRules, extensions, messageSnackbar, vDatePicker],
    props: {
      value: { type: Boolean, default: true },
      patientId: { type: String, default: null },
    },
    data: () => ({
      tab: null,
      requesting: false,
      isFormValid: false,
      pickBirthDateDialogIsOpen: false,
      birthDatePickerKey: 0,
      patientData: null,
      patientDataTemplate: {
        profile: {
          fullName: null,
          idNumber: null,
          birthDate: '1971-01-01',
        },
        bloodPressureGoals: {
          timeMode: 'dayNight',
          alltime: {
            bloodPressureThreshold: {
              systolic: 130,
              diastolic: 80,
            },
          },
          daytime: {
            beginHour: 8,
            bloodPressureThreshold: {
              systolic: 135,
              diastolic: 85,
            },
          },
          nighttime: {
            beginHour: 20,
            bloodPressureThreshold: {
              systolic: 120,
              diastolic: 70,
            },
          },
        },
        measurementPlanInExecution: null,
      },
    }),
    computed: {
      birthDateString() {
        if (!_.isString(this.patientData.profile.birthDate)) return null;
        return dateTimeString.toDate(this.patientData.profile.birthDate).rocDateString;
      },
      ageString() {
        if (!_.isString(this.patientData.profile.birthDate)) return '';
        const birthDate = dateTimeString.toDate(this.patientData.profile.birthDate);
        const age = (() => {
          const today = Date.withoutSeconds();
          const age = today.getFullYear() - birthDate.getFullYear();
          const month = today.getMonth() - birthDate.getMonth();
          if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) return age - 1;
          return age;
        })();
        return `（${age}歲）`;
      },
    },
    watch: {
      pickBirthDateDialogIsOpen(value) {
        if (!value) this.birthDatePickerKey += 1;
      },
    },
    created() {
      this.patientData = _.cloneDeep(
        _.isString(this.patientId)
          ? this.$store.state.user.ownedPatients[this.patientId]
          : this.patientDataTemplate,
      );
    },
    methods: {
      async done() {
        this.requesting = true;
        const patients = firestore.collection('patients');
        await (() => {
          if (!_.isString(this.patientId)) {
            const patient = patients.doc();
            const user = firestore.collection('users').doc(this.$store.state.profile.uid);
            return user
              .update({ [`ownedPatients.${patient.id}`]: null })
              .then(() => patient.set(this.patientData))
              .then(() => user.update({ [`ownedPatients.${patient.id}`]: patient }));
          } else {
            return patients.doc(this.patientId).update(this.patientData);
          }
        })()
          .then(() => this.close())
          .catch((error) =>
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定'),
          );
        this.requesting = false;
      },
      close() {
        this.$_mixin_messageSnackbar_hide();
        this.$emit('input', false);
      },
    },
  }),
);
</script>

import { Date } from '@/extensions';

export function fromDate(date, hasTime = true) {
  if (!date) return null;
  const year = `${date.getFullYear()}`.padStart(4, '0');
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  if (hasTime) {
    const hour = `${date.getHours()}`.padStart(2, '0');
    const minute = `${date.getMinutes()}`.padStart(2, '0');
    return `${year}-${month}-${day} ${hour}:${minute}`;
  }
  return `${year}-${month}-${day}`;
}

export function toDate(dateTimeString) {
  if (!dateTimeString) return null;
  const splitedDateTimeString = dateTimeString.split(' ');
  const splitedDateString = splitedDateTimeString[0].split('-');
  const splitedTimeString =
    splitedDateTimeString.length > 1 ? splitedDateTimeString[1].split(':') : null;
  return Date.withoutSeconds(
    parseInt(splitedDateString[0], 10),
    parseInt(splitedDateString[1] ?? '01', 10) - 1,
    parseInt(splitedDateString[2] ?? '01', 10),
    splitedTimeString ? parseInt(splitedTimeString[0], 10) : null,
    splitedTimeString ? parseInt(splitedTimeString[1], 10) : null,
  );
}

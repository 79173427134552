import Vue from 'vue';

import { toDate } from '@/utils/dateTimeString';

export default Vue.extend({
  methods: {
    $_mixin_vDatePicker_dayFormat(dateTimeString) {
      return toDate(dateTimeString).getDate().toString();
    },
    $_mixin_vDatePicker_rocYearFormat(yearString) {
      const rocYear = parseInt(yearString) - 1911;
      return '民國' + (rocYear > 0 ? `${rocYear}` : `前${(rocYear - 1) * -1}`) + '年';
    },
    $_mixin_vDatePicker_rocHeaderDateFormat(yearMonthString) {
      const date = toDate(yearMonthString);
      const formatedRocYearString = this.$_mixin_vDatePicker_rocYearFormat(date.getFullYear());
      return formatedRocYearString + `${date.getMonth() + 1}月`;
    },
  },
});

<template>
  <v-app>
    <v-navigation-drawer
      v-model="navDrawerIsOpen"
      app
      clipped
      :expand-on-hover="!$vuetify.breakpoint.mobile"
      :permanent="!$vuetify.breakpoint.mobile"
      :touchless="!$vuetify.breakpoint.mobile"
    >
      <v-list dense>
        <v-list-item v-for="(item, i) in navListItems" :key="`navListItems-${i}`" :to="item.path">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- FIXME: `v-app-bar` overflowed in report page (caused by `print-icon-button`) -->
    <!--        in Firefox Mobile, temporarily fixed by adding `style="width: 100vw"` -->
    <v-app-bar app clipped-left color="primary" dark dense style="width: 100vw">
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mobile"
        @click.stop="navDrawerIsOpen = !navDrawerIsOpen"
      />
      <v-icon v-else class="ml-n3" style="width: 48px; height: 48px">
        mdi-{{ $store.state.app.icon }}
      </v-icon>
      <v-app-bar-title class="pl-0">{{ $store.state.app.name }}</v-app-bar-title>
      <v-spacer />
      <template v-if="!requesting && !isUnderMaintenance">
        <v-menu
          v-if="_.isPlainObject($store.state.profile)"
          max-width="240px"
          offset-y
          transition="slide-y-transition"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              text
              class="ml-1 pa-1"
              min-width="0"
              height="48"
              :style="{ borderRadius: `${$vuetify.breakpoint.mobile ? 24 : 16}px` }"
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar class="ma-1" size="32">
                <v-img :src="photoUrl" :lazy-src="lazyPhotoUrl" style="background-color: #e5e5e5" />
              </v-avatar>
              <span
                v-if="!$vuetify.breakpoint.mobile"
                class="ma-1 subtitle-1"
                style="text-transform: none"
              >
                {{ $store.state.profile.name }}
              </span>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-img
                    :src="photoUrl"
                    :lazy-src="lazyPhotoUrl"
                    style="background-color: #e5e5e5"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ $store.state.profile.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $store.state.profile.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <!-- <v-btn icon>
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn> -->
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <measurement-manage-plan-dialog v-model="measurementManagePlanDialogIsOpen">
                <template #activator="{ on, attrs }">
                  <v-btn color="teal" outlined text block v-bind="attrs" v-on="on">
                    <v-icon left>mdi-clipboard-text-multiple</v-icon>
                    量測計畫管理
                  </v-btn>
                </template>
              </measurement-manage-plan-dialog>
            </v-card-actions>
            <v-divider />
            <v-card-actions>
              <v-btn color="pink" outlined text block @click="signOut()">
                <v-icon left>mdi-logout-variant</v-icon>
                登出
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-menu
          v-if="_.isPlainObject($store.state.profile) && $store.state.user"
          v-model="patientsMenuIsOpen"
          max-width="240px"
          offset-y
          transition="slide-y-transition"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              text
              class="ml-1 pa-1"
              min-width="0"
              height="48"
              style="border-radius: 16px"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-clipboard-account</v-icon>
              <span class="ma-1 subtitle-1" style="text-transform: none">
                {{
                  $store.state.patient
                    ? $store.state.patient.profile.fullName
                    : requesting
                    ? '載入中…'
                    : '未選擇病人'
                }}
              </span>
            </v-btn>
          </template>
          <v-card>
            <template v-if="$store.state.patient">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ $store.state.patient.profile.fullName }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $store.state.patient.profile.idNumber }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <add-or-edit-patient-dialog
                    :key="editPatientDialogKey"
                    v-model="editPatientDialogIsOpen"
                    :patient-id="$store.state.patientId"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-account-edit</v-icon>
                      </v-btn>
                    </template>
                  </add-or-edit-patient-dialog>
                </v-list-item-action>
              </v-list-item>
              <v-card-actions class="flex-column py-1">
                <edit-blood-pressure-goals-dialog
                  :key="'bpg' + editBloodPressureGoalsDialogKey"
                  v-model="editBloodPressureGoalsDialogIsOpen"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="deep-orange"
                      class="ma-1"
                      outlined
                      text
                      block
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon left>mdi-water-check</v-icon>
                      編輯血壓目標
                    </v-btn>
                  </template>
                </edit-blood-pressure-goals-dialog>
                <measurement-select-plan-dialog
                  :key="'msp' + measurementSelectPlanDialogKey"
                  v-model="measurementSelectPlanDialogIsOpen"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn color="green" class="ma-1" outlined text block v-bind="attrs" v-on="on">
                      <v-icon left>mdi-clipboard-text-play</v-icon>
                      選擇／清除量測計畫
                    </v-btn>
                  </template>
                </measurement-select-plan-dialog>
              </v-card-actions>
              <v-divider />
              <v-card-subtitle class="pa-2 mb-n2">執行中的量測計畫：</v-card-subtitle>
              <v-list-item :two-line="hasMeasurementPlanInExecution">
                <v-list-item-content v-if="hasMeasurementPlanInExecution">
                  <v-list-item-title>
                    {{ $store.state.patient.measurementPlanInExecution.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    開始時間：{{
                      Date$.withoutSeconds(
                        $store.state.patient.measurementPlanInExecution.beginAt.toDate(),
                      ).shortLocalizedString
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title class="text--disabled">（未選擇計畫）</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <measurement-manage-schedule-dialog v-model="measurementManageScheduleDialogIsOpen">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="teal"
                      outlined
                      text
                      block
                      :disabled="!hasMeasurementPlanInExecution"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon left>mdi-clipboard-clock</v-icon>
                      量測排程管理
                    </v-btn>
                  </template>
                </measurement-manage-schedule-dialog>
              </v-card-actions>
              <v-divider />
            </template>
            <v-list dense subheader style="max-height: 240px" class="overflow-y-auto">
              <v-subheader>病人列表</v-subheader>
              <template v-if="Object.keys($store.state.user.ownedPatients).length > 0">
                <v-list-item
                  v-for="(patient, patientId) in $store.state.user.ownedPatients"
                  :key="`userOwnedPatients-${patientId}`"
                  @click="changePatient(patientId)"
                >
                  <template v-if="patient !== null && $store.state.patientId !== patientId">
                    <v-list-item-avatar>
                      <v-icon small>mdi-radiobox-blank</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content v-if="patient.profile">
                      <v-list-item-title>{{ patient.profile.fullName }}</v-list-item-title>
                      <v-list-item-subtitle>{{ patient.profile.idNumber }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template v-else-if="patient !== null">
                    <v-list-item-avatar>
                      <v-icon small>mdi-radiobox-marked</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content v-if="patient.profile">
                      <v-list-item-title>{{ patient.profile.fullName }}</v-list-item-title>
                      <v-list-item-subtitle>{{ patient.profile.idNumber }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
              <v-list-item v-else>
                <v-list-item-content class="text--disabled">
                  <v-list-item-title>（無病人）</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <add-or-edit-patient-dialog
                :key="addPatientDialogKey"
                v-model="addPatientDialogIsOpen"
              >
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" outlined text block v-bind="attrs" v-on="on">
                    <v-icon left>mdi-account-plus</v-icon>
                    新增病人
                  </v-btn>
                </template>
              </add-or-edit-patient-dialog>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
      <v-menu v-model="appMenuIsOpen" left offset-y transition="slide-y-transition">
        <template #activator="{ on, attrs }">
          <v-btn class="ml-1" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="$vuetify.theme.dark = !$vuetify.theme.dark">
            <v-list-item-icon>
              <v-icon>
                mdi-{{ $vuetify.theme.dark ? 'weather-night' : 'white-balance-sunny' }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              主題：{{ `${$vuetify.theme.dark ? '暗' : '亮'}` }}
            </v-list-item-title>
          </v-list-item>
          <about-dialog
            v-model="aboutDialogIsOpen"
            @input="$event ? null : (appMenuIsOpen = false)"
          >
            <template #activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-icon>
                  <v-icon>mdi-information</v-icon>
                </v-list-item-icon>
                <v-list-item-title>關於</v-list-item-title>
              </v-list-item>
            </template>
          </about-dialog>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view v-if="!requesting && !isUnderMaintenance" style="height: calc(100vh - 48px)" />
      </v-fade-transition>
    </v-main>
    <sign-in-register-dialog v-model="signInRegisterDialogIsOpen" />
    <v-fade-transition>
      <v-overlay v-if="requesting || isUnderMaintenance" z-index="2147483647">
        <v-progress-circular v-if="requesting" indeterminate size="64" />
        <span v-else class="text-h3">維護中，系統已經暫時下線…</span>
      </v-overlay>
    </v-fade-transition>
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_isShowing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
      @result="result = $event"
    />
  </v-app>
</template>

<script>
import Vue from 'vue';
import md5 from 'md5';

import AboutDialog from '@/components/App/AboutDialog';
import MeasurementManagePlanDialog from '@/components/App/Measurement/MeasurementManagePlanDialog';
import MeasurementManageScheduleDialog from '@/components/App/Measurement/MeasurementManageScheduleDialog';
import MeasurementSelectPlanDialog from '@/components/App/Measurement/MeasurementSelectPlanDialog';
import AddOrEditPatientDialog from '@/components/App/AddOrEditPatientDialog';
import EditBloodPressureGoalsDialog from '@/components/App/EditBloodPressureGoalsDialog';
import SignInRegisterDialog from '@/components/App/SignInRegisterDialog';
import extensions from '@/mixins/extensions';
import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
import { auth } from '@/firebase';

export default Vue.extend({
  name: 'App',
  components: {
    AboutDialog,
    MeasurementManagePlanDialog,
    MeasurementManageScheduleDialog,
    MeasurementSelectPlanDialog,
    AddOrEditPatientDialog,
    EditBloodPressureGoalsDialog,
    SignInRegisterDialog,
  },
  mixins: [extensions, messageSnackbar],
  data: () => ({
    navListItems: [
      {
        title: '報告',
        path: '/report',
        icon: 'mdi-file-chart',
      },
      {
        title: '紀錄',
        path: '/records',
        icon: 'mdi-format-list-bulleted',
      },
      {
        title: '趨勢',
        path: '/trends',
        icon: 'mdi-chart-line',
      },
    ],
    navDrawerIsOpen: true,
    patientsMenuIsOpen: false,
    appMenuIsOpen: false,
    aboutDialogIsOpen: false,
    addPatientDialogKey: 0,
    addPatientDialogIsOpen: false,
    editPatientDialogKey: 0,
    editPatientDialogIsOpen: false,
    editBloodPressureGoalsDialogKey: 0,
    editBloodPressureGoalsDialogIsOpen: false,
    measurementSelectPlanDialogKey: 0,
    measurementSelectPlanDialogIsOpen: false,
    measurementManagePlanDialogIsOpen: false,
    measurementManageScheduleDialogIsOpen: false,
    signInRegisterDialogIsOpen: false,
    requesting: false,
  }),
  computed: {
    isUnderMaintenance() {
      return (
        this.$store.state.globalOptions?.isUnderMaintenance &&
        process.env.NODE_ENV !== 'development'
      );
    },
    photoUrl() {
      const profile = this.$store.state.profile;
      if (_.isString(profile.photoUrl)) return profile.photoUrl;
      return this.getGravatarUrl(profile.email, Math.round(40 * window.devicePixelRatio));
    },
    lazyPhotoUrl() {
      return this.getGravatarUrl(this.$store.state.profile.email, 8);
    },
    hasMeasurementPlanInExecution() {
      return _.isPlainObject(this.$store.state.patient.measurementPlanInExecution);
    },
  },
  watch: {
    addPatientDialogIsOpen(value) {
      if (!value) this.addPatientDialogKey += 1;
    },
    editPatientDialogIsOpen(value) {
      if (!value) this.editPatientDialogKey += 1;
    },
    editBloodPressureGoalsDialogIsOpen(value) {
      if (!value) this.editBloodPressureGoalsDialogKey += 1;
    },
    measurementSelectPlanDialogIsOpen(value) {
      if (!value) this.measurementSelectPlanDialogKey += 1;
    },
  },
  async created() {
    this.requesting = true;
    await this.$store.dispatch('bindGlobalOptions');
    this.signInRegisterDialogIsOpen = !_.isPlainObject(this.$store.state.profile);
    this.requesting = false;
    if (_.isPlainObject(this.$store.state.profile)) {
      this.requesting = true;
      await this.bindUserRelated();
      this.requesting = false;
      await this.changePatient();
    }
  },
  destroyed() {
    this.$store.dispatch('unbindGlobalOptions');
  },
  methods: {
    getGravatarUrl(email, size) {
      const protocol = window.location.protocol;
      const hashed = md5(email.trim().toLowerCase());
      return `${protocol}//www.gravatar.com/avatar/${hashed}?s=${size}&d=identicon&r=g`;
    },
    async bindUserRelated() {
      await this.$store.dispatch('bindUser', this.$store.state.profile.uid);
      await this.$store.dispatch('bindMeasurementPlans', this.$store.state.profile.uid);
    },
    unbindUserRelated() {
      this.$store.dispatch('unbindUser', this.$store.state.profile.uid);
      this.$store.dispatch('unbindMeasurementPlans', this.$store.state.profile.uid);
    },
    async bindPatientRelated() {
      if (!_.isString(this.$store.state.patientId)) return;
      await this.$store.dispatch('bindPatient', this.$store.state.patientId);
      await this.$store.dispatch('bindMeasurementSchedules', this.$store.state.patientId);
      await this.$store.dispatch('bindMeasurementRecords', this.$store.state.patientId);
    },
    unbindPatientRelated() {
      if (!_.isString(this.$store.state.patientId)) return;
      this.$store.dispatch('unbindPatient', this.$store.state.patientId);
      this.$store.dispatch('unbindMeasurementSchedules', this.$store.state.patientId);
      this.$store.dispatch('unbindMeasurementRecords', this.$store.state.patientId);
    },
    async signOut() {
      this.requesting = true;
      this.unbindPatientRelated();
      this.unbindUserRelated();
      await auth
        .signOut()
        .catch((error) =>
          this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定'),
        );
      this.requesting = false;
    },
    async changePatient(patientId = localStorage.getItem('app.patientId')) {
      this.patientsMenuIsOpen = false;
      this.requesting = true;
      this.unbindPatientRelated();
      const patientIds = Object.keys(this.$store.state.user.ownedPatients);
      if (patientIds.length > 0) {
        if (!patientIds.includes(patientId)) patientId = patientIds[0];
        this.$store.commit('patientId', patientId);
        await this.bindPatientRelated();
        localStorage.setItem('app.patientId', patientId);
      }
      this.requesting = false;
    },
  },
});
</script>
